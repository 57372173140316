import React from "react";
import { v4 as uuid } from "uuid";
import { ROUTENAMES } from "routes/routeNames.js";
import {
  SparePartsCategory,
  SparePartSinglePage,
} from "spareParts/containers";
import {
  About,
  Blog,
  BlogPage,
  CarCamera360Degree,
  CarPage,
  Cars,
  CarWithType,
  Contact,
  CreditField,
  NotFound,
  ProfilePage,
  Reviews,
  SavedCars,
  SeeReviews,
  ServicePage,
  Services,
  SignIn,
  SignUp,
  Welcome,
  WriteReview,
  OurAdresses,
  CarCamera360DegreeID6,
  AvailableCars,
  CarCamera360DegreeHondaEns1,
  PrivacyPolicy,
  CompareCars,
  FAQ,
  CarCamera360DegreeMercedesEQS,
  CarCamera360DegreeMercedesToyotaBZ4X,
  Motorcycles,
  MopedsSinglePage,
  MotorcyclesActive,
} from "containers";
import { CarCamera360DegreeMitsubishi } from "containers/360cameraMitsubishi";

export const NewWebsiteRoutes = [
  {
    id: uuid(),
    name: "Blog",
    component: <Blog />,
    path: ROUTENAMES.blog,
    is_private: false
  },
  {
    id: uuid(),
    name: "Blog_slug",
    component: <BlogPage />,
    path: ROUTENAMES.blog_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "About",
    component: <About />,
    path: ROUTENAMES.about,
    is_private: false
  },
  {
    id: uuid(),
    name: "carWithType",
    component: <CarWithType />,
    path: ROUTENAMES.car_with_type_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "Welcome",
    component: <Welcome />,
    path: ROUTENAMES.welcome,
    is_private: false
  },
  {
    id: uuid(),
    name: "OurAdresses",
    component: <OurAdresses />,
    path: ROUTENAMES.our_addresses,
    is_private: false
  },
  {
    id: uuid(),
    name: "Contact",
    component: <Contact />,
    path: `${ROUTENAMES.contact}`,
    is_private: false
  },
  {
    id: uuid(),
    name: "AvailableCars",
    component: <AvailableCars />,
    path: ROUTENAMES.available_cars,
    is_private: false
  },
  {
    id: uuid(),
    name: "Active Motorcycles",
    component: <MotorcyclesActive />,
    path: ROUTENAMES.active_motorcycles,
    is_private: false
  },
  {
    id: uuid(),
    name: "Cars",
    component: <Cars />,
    path: ROUTENAMES.cars,
    is_private: false
  },
  {
    id: uuid(),
    name: "Cars_Search",
    component: <Cars />,
    path: ROUTENAMES.cars_search,
    is_private: false
  },
  {
    id: uuid(),
    name: "Cars_slug",
    component: <CarPage />,
    path: ROUTENAMES.cars_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "Car",
    component: <CarPage />,
    path: ROUTENAMES.car,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles single",
    component: <MopedsSinglePage />,
    path: ROUTENAMES.motorcycles_single_page,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles single",
    component: <MopedsSinglePage />,
    path: ROUTENAMES.motorcycles_single_page_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles",
    component: <Motorcycles />,
    path: ROUTENAMES.motorcycles,
    is_private: false
  },
];

export const WebsiteRoutes = [
  {
    id: uuid(),
    name: "Welcome",
    component: <Welcome />,
    path: ROUTENAMES.welcome,
    is_private: false
  },
  {
    id: uuid(),
    name: "Contact",
    component: <Contact />,
    path: `${ROUTENAMES.contact}/*`,
    is_private: false
  },
  {
    id: uuid(),
    name: "Profile",
    component: ProfilePage,
    path: ROUTENAMES.profile,
    is_private: true
  },
  {
    id: uuid(),
    name: "Cars",
    component: <Cars />,
    path: ROUTENAMES.cars,
    is_private: false
  },
  {
    id: uuid(),
    name: "Cars_Search",
    component: <Cars />,
    path: ROUTENAMES.cars_search,
    is_private: false
  },
  {
    id: uuid(),
    name: "Cars_slug",
    component: <CarPage />,
    path: ROUTENAMES.cars_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "Car",
    component: <CarPage />,
    path: ROUTENAMES.car,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles single",
    component: <MopedsSinglePage />,
    path: ROUTENAMES.motorcycles_single_page,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles single",
    component: <MopedsSinglePage />,
    path: ROUTENAMES.motorcycles_single_page_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "Sign_in",
    component: <SignIn />,
    path: ROUTENAMES.sign_in,
    is_private: false
  },
  {
    id: uuid(),
    name: "Sign_up",
    component: <SignUp />,
    path: ROUTENAMES.sign_up,
    is_private: false
  },
  {
    id: uuid(),
    name: "Blog",
    component: <Blog />,
    path: ROUTENAMES.blog,
    is_private: false
  },
  {
    id: uuid(),
    name: "Blog_slug",
    component: <BlogPage />,
    path: ROUTENAMES.blog_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "About",
    component: <About />,
    path: ROUTENAMES.about,
    is_private: false
  },
  {
    id: uuid(),
    name: "SavedCars",
    component: SavedCars,
    path: ROUTENAMES.saved_cars,
    is_private: true
  },
  {
    id: uuid(),
    name: "Not Found",
    component: <NotFound />,
    path: ROUTENAMES.not_found,
    is_private: false
  },
  {
    id: uuid(),
    name: "Services",
    component: <Services />,
    path: ROUTENAMES.services,
    is_private: false
  },
  {
    id: uuid(),
    name: "Services",
    component: <ServicePage />,
    path: ROUTENAMES.services_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "carWithType",
    component: <CarWithType />,
    path: ROUTENAMES.car_with_type,
    is_private: false
  },
  {
    id: uuid(),
    name: "carWithType",
    component: <CarWithType />,
    path: ROUTENAMES.car_with_type_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "reviews",
    component: <Reviews />,
    path: ROUTENAMES.reviews,
    is_private: false
  },
  {
    id: uuid(),
    name: "seeReviews",
    component: <SeeReviews />,
    path: ROUTENAMES.see_reviews,
    is_private: false
  },
  {
    id: uuid(),
    name: "writeReviews",
    component: <WriteReview />,
    path: ROUTENAMES.write_reviews,
    is_private: false
  },
  {
    id: uuid(),
    name: "CreditField",
    component: <CreditField />,
    path: ROUTENAMES.credit_calculator,
    is_private: false
  },
  {
    id: uuid(),
    name: "SparePartsCategory",
    component: <SparePartsCategory />,
    path: ROUTENAMES.main_spare_parts,
    is_private: false
  },
  {
    id: uuid(),
    name: "SparePartsCategorySlug",
    component: <SparePartsCategory />,
    path: ROUTENAMES.car_spare_parts_category_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "CarCamera360Degree",
    component: <CarCamera360Degree />,
    path: ROUTENAMES.camera_360,
    is_private: false
  },
  {
    id: uuid(),
    name: "CarCamera360DegreeID6",
    component: <CarCamera360DegreeID6 />,
    path: ROUTENAMES.camera_360_ID6,
    is_private: false
  },
  {
    id: uuid(),
    name: "SparePartSinglePage",
    component: <SparePartSinglePage />,
    path: ROUTENAMES.car_spare_part_single_page,
    is_private: false
  },
  {
    id: uuid(),
    name: "SparePartSinglePageSlug",
    component: <SparePartSinglePage />,
    path: ROUTENAMES.car_spare_part_single_page_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "OurAdresses",
    component: <OurAdresses />,
    path: ROUTENAMES.our_addresses,
    is_private: false
  },
  {
    id: uuid(),
    name: "AvailableCars",
    component: <AvailableCars />,
    path: ROUTENAMES.available_cars,
    is_private: false
  },
  {
    id: uuid(),
    name: "CarCameraHondaEns1",
    component: <CarCamera360DegreeHondaEns1 />,
    path: ROUTENAMES.camera_360_Honda_Ens1,
    is_private: false
  },
  {
    id: uuid(),
    name: "CompareCarModels",
    component: <CompareCars />,
    path: ROUTENAMES.cars_compare,
    is_private: false
  },
  {
    id: uuid(),
    name: "CompareCarModels",
    component: <CompareCars />,
    path: ROUTENAMES.cars_compare_slug,
    is_private: false
  },
  {
    id: uuid(),
    name: "PrivacyPolicy",
    component: <PrivacyPolicy />,
    path: ROUTENAMES.privacy_policy,
    is_private: false
  },
  {
    id: uuid(),
    name: "Frequently Asked Questions",
    component: <FAQ />,
    path: ROUTENAMES.faq,
    is_private: false
  },
  {
    id: uuid(),
    name: "EQS 360 Camera",
    component: <CarCamera360DegreeMercedesEQS />,
    path: ROUTENAMES.camera_360_EQS,
    is_private: false
  },
  {
    id: uuid(),
    name: "BZ4X 360 camera",
    component: <CarCamera360DegreeMercedesToyotaBZ4X />,
    path: ROUTENAMES.camera_360_bz_4_x,
    is_private: false
  },
  {
    id: uuid(),
    name: "Mitsubishi 360 camera",
    component: <CarCamera360DegreeMitsubishi />,
    path: ROUTENAMES.mitsubishi_360,
    is_private: false
  },
  {
    id: uuid(),
    name: "Motorcycles",
    component: <Motorcycles />,
    path: ROUTENAMES.motorcycles,
    is_private: false
  },
  {
    id: uuid(),
    name: "Active Motorcycles",
    component: <MotorcyclesActive />,
    path: ROUTENAMES.active_motorcycles,
    is_private: false
  },
];