import styled from "styled-components";

export const StyledWelcomeTwoImagesWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    svg:nth-child(1) {
        width: 70%;
        flex-shrink: 1;
    }

    svg:nth-child(2) {
        width: 100%;
        flex-shrink: 1;
    }
    
    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;


        svg:nth-child(1) {
            width: 100%;
            flex-shrink: 1;
        }

        svg:nth-child(2) {
            width: 100%;
            flex-shrink: 1;
        }
    }
`;

