import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCars } from "store/cars/selector";
import { ROUTENAMES } from "routes/routeNames";
import { topCars } from "store/topAnnouncments/actions";
import "components/welcome/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IMAGE_STARTING_URL } from "utils/constants";
import { selectTopCars } from "store/topAnnouncments/selector";
import { NumberWithDots } from "HOC/numberWithDots";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  AnnouncmentsWraper, AvailableCarsBlock,
  CarDateAndPrice, CarDateText,
  CarMainBlock,
  CarMainBlockWrapper,
  CarNameDateAndePrice, CarNameForWelcome,
  CarPriceAndDateText,
  InvisibleH1,
  TopAnnouncmentsWrapper,
} from "components/welcome/styled.js";

export const AvailableCarsForSingle = ({ filteredCars }) => {
  const dispatch = useDispatch();
  const SelectTopCarData = useSelector(selectTopCars);
  const carMainBlockRef = useRef(null);

  useEffect(() => {
    if (SelectTopCarData.length) {
      return selectCars;
    } else {
      dispatch(topCars({ language: "am" }));
    }
  }, [dispatch]);

  return (
    <TopAnnouncmentsWrapper>
      <AvailableCarsBlock>
        <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
          Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>

        <AnnouncmentsWraper>
          <CarMainBlockWrapper ref={carMainBlockRef}>
            {filteredCars.map((element) => (
                <Link className="main__link" to={element.motorcycle_price ? `${ROUTENAMES.motorcycles_single_page}/${element.slug}` : `${ROUTENAMES.car}/${element.car_slug}`} target={"_blank"}
                      key={element.id}>
                  <CarMainBlock
                    key={element.car_slug}
                  >
                    {
                      element?.product_images.map((item) => {
                        return item?.main_image ? <LazyLoadImage
                          key={item.id}
                          effect="blur"
                          alt="main car image"
                          src={item?.main_image ? `${IMAGE_STARTING_URL}/${item?.image}` : null}
                        /> : null;
                      })
                    }
                    <CarNameDateAndePrice>
                      {element.product_name.car_name ?
                      <CarNameForWelcome isWelcome>{element.product_name.car_name} {element.product_model.model_name}</CarNameForWelcome>
                      : <CarNameForWelcome isWelcome>{element.product_name.motorcycle_name} {element.product_model.motorcycle_model}</CarNameForWelcome>}
                      <CarDateAndPrice>
                        <CarPriceAndDateText>
                          {element.motorcycle_price ? NumberWithDots(element.motorcycle_price) : NumberWithDots(element.car_price)} $
                        </CarPriceAndDateText>
                        <CarDateText>{element.product_year.car_year}</CarDateText>
                      </CarDateAndPrice>
                    </CarNameDateAndePrice>
                  </CarMainBlock>
                </Link>
              ))}
          </CarMainBlockWrapper>
        </AnnouncmentsWraper>
      </AvailableCarsBlock>
    </TopAnnouncmentsWrapper>
  );
};

