import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DateFormatter } from "utils/helpers";
import { Loading } from "components/loading";
import { selectBlog, selectBlogStatus } from "store/blog/selector";
import { blog } from "store/blog/actions";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { ROUTENAMES } from "routes/routeNames";
import { HeaderAdvertisementContainer } from "components/header/styled";
import { ArmenianLeasingAdvertisementImage } from "components/welcome/styled";
import { Images } from "assets";
import {
  BlogAnimatedTitle,
  BlogAuthor,
  BlogCard,
  BlogContainer,
  BlogDate,
  BlogDescription,
  BlogDetails,
  BlogTitle,
  BlogWrapper,
  Container,
} from "containers/blog/styled";

export const Blog = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const blogData = useSelector(selectBlog);
  const blogDataStatus = useSelector(selectBlogStatus);
  const { t } = useTranslation();

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(blog({ language }));
  }, [dispatch, language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <BlogContainer>
      <Container>
        <BlogAnimatedTitle>{t("blog")}</BlogAnimatedTitle>
      </Container>
      <BlogWrapper>
        {blogData?.map((element) => (
          <Link to={`${ROUTENAMES.blog}/${element.slug}`} target={"_blank"} key={`${element.slug}-${element.id}`}>
            <BlogCard className="blog-card" key={element.id}>
              <LazyLoadImage
                key={element.id}
                effect="blur"
                src={`${IMAGE_STARTING_URL}/${element?.image}`}
                alt="Blog Image"
              />

              <BlogDetails>
                <BlogTitle>{element?.name_blog}</BlogTitle>
                <BlogAuthor>{element?.blog_writer_name}</BlogAuthor>
                <BlogDate>
                  {element?.created_at && (
                    <DateFormatter date={element.created_at} />
                  )}
                </BlogDate>
                <BlogDescription>
                  {element?.blog_sample_description}
                </BlogDescription>
              </BlogDetails>
            </BlogCard>
          </Link>
        ))}
      </BlogWrapper>
      {blogDataStatus === REDUX_STATES.PENDING ? <Loading /> : null}
    </BlogContainer>
  );
};
