import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";

export const CarWithTypeMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarWithTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
  padding-inline: 200px;
  min-height: 600px;
  background: ${COLORS.white};
  height: auto;
  padding-bottom: 10px;

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
    justify-content: center;
  }
`;


export const CarWithTypeContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  justify-content: center;
  height: 100%;
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;


export const BlogAnimatedTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #000000;
  text-transform: capitalize;

  @media only screen and (max-width: 769px) {
    font-size: 2rem;
  }
`;
