import styled from "styled-components";

export const OurPartnersWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const OurPartnerImage = styled.img`
    width: 100%;
    object-fit: cover;
`;
