import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import React, { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectContact } from "store/contact/selector";
import { SuccessModal } from "modals/successModal";
import { contactPost } from "store/contact/actions";
import { MODAL_CONSTANTS, REDUX_STATES } from "utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContactValidationSchema } from "validations/contactValidation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { Images } from "assets/index";
import { toast } from "react-toastify";
import {
  ContactUsForm,
  ContactusMainForm,
  ContactUsTitle,
  ContactUsWrapper,
  InputsContainer,
  SendMessageButton,
  StyledErrorMessage,
  StyledInput,
  StyledInputTitle,
  StyledInputWrapper,
  StyledTextArea,
  StyledTextAreaWrapper
} from "containers/contact/styled.js";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";
import { resetStatus } from "../../store/contact/slice";
import { Carsheader } from "../../components/cars/carsheader";

export const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(useContactValidationSchema())
  });

  const onSubmit = (data) => {
    if (data.feedback_name && data.feedback_email && data.feedback_subject && data.feedback_phone && data.feedback_message) {
      dispatch(contactPost(data));
      toast.success('Message Sent');
      navigate(ROUTENAMES.welcome);
    }
  };

  return (
    <ContactUsWrapper>
      <Carsheader titleText={t("get_in_touch")} />

      <ContactusMainForm>
        <ContactUsForm onSubmit={handleSubmit(onSubmit)}>
          <ContactUsTitle>{t("get_in_touch")}</ContactUsTitle>
          <InputsContainer>
            <StyledInputWrapper>
              <StyledErrorMessage>{errors.feedback_name ? errors?.feedback_name?.message : null}</StyledErrorMessage>
              <StyledInputTitle>{t("name")}</StyledInputTitle>
              <StyledInput {...register("feedback_name")} />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <StyledErrorMessage>{errors.feedback_email ? errors?.feedback_email?.message : null}</StyledErrorMessage>
              <StyledInputTitle> {t("email")} </StyledInputTitle>
              <StyledInput {...register("feedback_email")} />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <StyledErrorMessage>{errors.feedback_subject ? errors?.feedback_subject?.message : null}</StyledErrorMessage>
              <StyledInputTitle> {t("subject")} </StyledInputTitle>
              <StyledInput {...register("feedback_subject")} />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <StyledErrorMessage>{errors.feedback_phone ? errors?.feedback_phone?.message : null}</StyledErrorMessage>
              <StyledInputTitle> {t("phone_number")} </StyledInputTitle>
              <StyledInput {...register("feedback_phone")} />
            </StyledInputWrapper>

            <StyledTextAreaWrapper>
              <StyledErrorMessage>{errors.feedback_message ? errors?.feedback_message?.message : null}</StyledErrorMessage>
              <StyledInputTitle> {t("message")} </StyledInputTitle>
              <StyledTextArea {...register("feedback_message")} />
            </StyledTextAreaWrapper>
          </InputsContainer>
          <SendMessageButton>{t("send_message")}</SendMessageButton>
        </ContactUsForm>
      </ContactusMainForm>
    </ContactUsWrapper>
  );
};
