import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { IMAGE_STARTING_URL } from "utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Thumbs } from "swiper";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./newStylesForSlider.css";


export const CarSpecialPageSlider = ({ carData }) => {
  const [imagesData, setImagesData] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  useEffect(() => {
    if (carData?.product_images) {
      let sortedImages = [...carData.product_images];

      const mainIndex = sortedImages.findIndex((img) => img.main_image === 1);
      if (mainIndex > 0) {
        const [mainImage] = sortedImages.splice(mainIndex, 1);
        sortedImages.unshift(mainImage);
      }
      setImagesData(sortedImages);
    }
  }, [carData?.product_images]);

  return (
    <div className="car-slider">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs]}
        className="main-slider"
      >
        {imagesData.map((image) => (
          <SwiperSlide key={image.id}>
            <LazyLoadImage
              effect="blur"
              src={`${IMAGE_STARTING_URL}/${image.image}`}
              alt="Main slide"
              className="slider-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[Thumbs]}
        className="thumb-slider"
      >
        {imagesData.map((image) => (
          <SwiperSlide key={image.id}>
            <LazyLoadImage
              effect="blur"
              src={`${IMAGE_STARTING_URL}/${image.image}`}
              alt="Thumbnail"
              className="thumb-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};