export const BigEcoMotorsLogo = () => (
  <svg width="231" height="63" viewBox="0 0 231 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.3917 49.374C33.6541 57.1277 23.7256 62.0269 12.6533 62.0269V40.3914C16.3065 43.5652 20.6029 46.0319 25.3126 47.5677L23.0611 55.1935L28.4758 48.4453C31.1257 49.0524 33.8842 49.374 36.7193 49.374H40.3917Z"
      fill="#008900" />
    <path
      d="M49.3738 0V21.6292C45.3905 18.1763 40.6574 15.5626 35.4492 14.0673L36.9211 9.08493L33.3489 13.5347C30.763 12.9575 28.0726 12.6507 25.3141 12.6507H21.6376C28.373 4.90139 38.3014 0 49.3738 0Z"
      fill="#008900" />
    <path
      d="M21.6356 12.6528C16.0419 19.0943 12.6529 27.5125 12.6529 36.7125V40.389C4.90139 33.6536 0 23.7252 0 12.6528H21.6356Z"
      fill="#4FEC00" />
    <path
      d="M62.0336 49.3739H40.3916C45.9853 42.9367 49.3743 34.5207 49.3743 25.3143V21.6313C57.1279 28.3689 62.0336 38.2974 62.0336 49.3739Z"
      fill="#4FEC00" />
    <path d="M35.1086 12.7509L33.9349 16.7256L29.8664 30.5096L22.59 28.3582L32.2585 16.3018L35.1086 12.7509Z"
          fill="black" />
    <path d="M38.0864 34.6953L29.8876 44.922L25.5656 50.309L27.3634 44.2233L30.8035 32.5503L38.0864 34.6953Z"
          fill="black" />
    <path
      d="M74.8711 20.9604H88.0182V24.6115H79.4359V26.552H85.2788V30.203H79.4359V32.1435H88.474V35.7967H74.8711V20.9604Z"
      fill="black" />
    <path
      d="M100.731 32.1435V30.2499L104.61 31.1616V32.9423C104.61 33.8093 104.352 34.5037 103.834 35.0192C103.317 35.5368 102.625 35.7946 101.758 35.7946H92.4682C91.6012 35.7946 90.9068 35.5368 90.3913 35.0192C89.8737 34.5016 89.616 33.8093 89.616 32.9423V23.8127C89.616 22.9457 89.8737 22.2534 90.3913 21.7358C90.9089 21.2182 91.6012 20.9604 92.4682 20.9604H101.53C102.397 20.9604 103.089 21.2203 103.607 21.7358C104.124 22.2534 104.382 22.9457 104.382 23.8127V25.5913L100.503 26.5051V24.6093H94.1808V32.1414H100.731V32.1435Z"
      fill="black" />
    <path
      d="M111.799 35.7946C110.581 35.7946 109.544 35.6114 108.683 35.2471C107.825 34.8807 107.124 34.3716 106.585 33.7177C106.044 33.0637 105.648 32.2799 105.399 31.3661C105.147 30.4544 105.021 29.4575 105.021 28.3775C105.021 27.2358 105.147 26.2048 105.399 25.2846C105.65 24.3644 106.044 23.5847 106.585 22.9457C107.124 22.3067 107.825 21.8168 108.683 21.4738C109.544 21.1309 110.581 20.9604 111.799 20.9604H115.201C116.418 20.9604 117.466 21.1394 118.339 21.4972C119.212 21.8551 119.932 22.3557 120.495 23.0032C121.057 23.6508 121.472 24.4304 121.739 25.3421C122.005 26.2538 122.139 27.2677 122.139 28.3775C122.139 29.4276 122.005 30.4054 121.739 31.3107C121.472 32.216 121.057 32.9998 120.495 33.6623C119.932 34.3248 119.212 34.8445 118.339 35.2258C117.463 35.6071 116.418 35.7967 115.201 35.7967H111.799V35.7946ZM115.293 32.1435C115.734 32.1435 116.096 32.037 116.377 31.824C116.658 31.611 116.88 31.3298 117.04 30.9805C117.199 30.6312 117.308 30.2307 117.37 29.7812C117.432 29.3339 117.461 28.8653 117.461 28.3775C117.461 27.8897 117.432 27.4232 117.37 26.9738C117.308 26.5243 117.199 26.126 117.04 25.7766C116.88 25.4252 116.658 25.1461 116.377 24.9331C116.094 24.7201 115.734 24.6136 115.293 24.6136H112.098C111.657 24.6136 111.284 24.7201 110.979 24.9331C110.675 25.1461 110.428 25.4273 110.238 25.7766C110.049 26.126 109.91 26.5264 109.827 26.9738C109.744 27.4232 109.701 27.8918 109.701 28.3775C109.701 28.8632 109.744 29.3339 109.827 29.7812C109.91 30.2307 110.046 30.6312 110.238 30.9805C110.428 31.332 110.675 31.6131 110.979 31.824C111.282 32.037 111.657 32.1435 112.098 32.1435H115.293Z"
      fill="black" />
    <path
      d="M129.901 20.9604H134.465L138.802 28.1048L143.139 20.9604H147.704V35.7967H143.139V28.6991L139.876 35.7967H137.731L134.468 28.6991V35.7967H129.903V20.9604H129.901Z"
      fill="black" />
    <path
      d="M156.307 35.7946C155.089 35.7946 154.052 35.6114 153.191 35.2471C152.333 34.8807 151.632 34.3716 151.093 33.7177C150.552 33.0637 150.156 32.2799 149.906 31.3661C149.655 30.4544 149.529 29.4575 149.529 28.3775C149.529 27.2358 149.655 26.2048 149.906 25.2846C150.158 24.3644 150.552 23.5847 151.093 22.9457C151.632 22.3067 152.333 21.8168 153.191 21.4738C154.052 21.1309 155.089 20.9604 156.307 20.9604H159.709C160.925 20.9604 161.973 21.1394 162.847 21.4972C163.72 21.8551 164.44 22.3557 165.002 23.0032C165.565 23.6508 165.98 24.4304 166.246 25.3421C166.513 26.2538 166.647 27.2677 166.647 28.3775C166.647 29.4276 166.513 30.4054 166.246 31.3107C165.98 32.216 165.565 32.9998 165.002 33.6623C164.44 34.3248 163.72 34.8445 162.847 35.2258C161.971 35.6071 160.925 35.7967 159.709 35.7967H156.307V35.7946ZM159.799 32.1435C160.24 32.1435 160.602 32.037 160.883 31.824C161.164 31.611 161.386 31.3298 161.545 30.9805C161.705 30.6312 161.814 30.2307 161.875 29.7812C161.937 29.3339 161.967 28.8653 161.967 28.3775C161.967 27.8897 161.937 27.4232 161.875 26.9738C161.814 26.5243 161.705 26.126 161.545 25.7766C161.386 25.4252 161.164 25.1461 160.883 24.9331C160.6 24.7201 160.24 24.6136 159.799 24.6136H156.603C156.162 24.6136 155.79 24.7201 155.485 24.9331C155.18 25.1461 154.933 25.4273 154.744 25.7766C154.554 26.126 154.416 26.5264 154.333 26.9738C154.25 27.4232 154.207 27.8918 154.207 28.3775C154.207 28.8632 154.25 29.3339 154.333 29.7812C154.416 30.2307 154.552 30.6312 154.744 30.9805C154.933 31.332 155.18 31.6131 155.485 31.824C155.788 32.037 156.162 32.1435 156.603 32.1435H159.799Z"
      fill="black" />
    <path
      d="M167.105 20.9604H180.365V24.6115H175.8L174.179 30.203H176.188L173.653 35.7946H176.233L169.591 41.0666L171.532 35.7946H168.724L171.259 30.203H169.615L171.236 24.6115H167.105V20.9604Z"
      fill="black" />
    <path
      d="M187.648 35.7946C186.43 35.7946 185.392 35.6114 184.532 35.2471C183.673 34.8807 182.973 34.3716 182.434 33.7177C181.893 33.0637 181.496 32.2799 181.247 31.3661C180.996 30.4544 180.87 29.4575 180.87 28.3775C180.87 27.2358 180.996 26.2048 181.247 25.2846C181.499 24.3644 181.893 23.5847 182.434 22.9457C182.973 22.3067 183.673 21.8168 184.532 21.4738C185.392 21.1309 186.43 20.9604 187.648 20.9604H191.05C192.266 20.9604 193.314 21.1394 194.188 21.4972C195.061 21.8551 195.781 22.3557 196.343 23.0032C196.906 23.6508 197.321 24.4304 197.587 25.3421C197.854 26.2538 197.988 27.2677 197.988 28.3775C197.988 29.4276 197.854 30.4054 197.587 31.3107C197.321 32.216 196.906 32.9998 196.343 33.6623C195.781 34.3248 195.061 34.8445 194.188 35.2258C193.312 35.6071 192.266 35.7967 191.05 35.7967H187.648V35.7946ZM191.139 32.1435C191.58 32.1435 191.942 32.037 192.224 31.824C192.505 31.611 192.726 31.3298 192.886 30.9805C193.046 30.6312 193.154 30.2307 193.216 29.7812C193.278 29.3339 193.308 28.8653 193.308 28.3775C193.308 27.8897 193.278 27.4232 193.216 26.9738C193.154 26.5243 193.046 26.126 192.886 25.7766C192.726 25.4252 192.505 25.1461 192.224 24.9331C191.94 24.7201 191.58 24.6136 191.139 24.6136H187.944C187.503 24.6136 187.131 24.7201 186.826 24.9331C186.521 25.1461 186.274 25.4273 186.085 25.7766C185.895 26.126 185.757 26.5264 185.674 26.9738C185.59 27.4232 185.548 27.8918 185.548 28.3775C185.548 28.8632 185.59 29.3339 185.674 29.7812C185.757 30.2307 185.893 30.6312 186.085 30.9805C186.274 31.332 186.521 31.6131 186.826 31.824C187.128 32.037 187.503 32.1435 187.944 32.1435H191.139Z"
      fill="black" />
    <path
      d="M199.814 20.9604H209.263C210.999 20.9604 212.283 21.4014 213.12 22.2854C213.958 23.1672 214.375 24.424 214.375 26.0514C214.375 27.3444 214.109 28.4073 213.576 29.2359C213.044 30.0646 212.23 30.6312 211.133 30.9358L214.671 35.867H209.604L206.225 31.1658H204.376V35.7988H199.812V20.9626L199.814 20.9604ZM204.378 27.5105H207.757C208.396 27.5105 208.879 27.3614 209.205 27.0654C209.533 26.7693 209.695 26.4306 209.695 26.0493C209.695 25.668 209.531 25.3527 209.205 25.0567C208.879 24.7606 208.396 24.6115 207.757 24.6115H204.378V27.5105Z"
      fill="black" />
    <path
      d="M222.301 35.7946C220.171 35.7946 218.627 35.5773 217.668 35.1428C216.71 34.7083 216.23 33.9755 216.23 32.9403V31.1829L220.109 30.2691V32.1415H226.431V30.2009H221.592C220.77 30.2009 220.024 30.1243 219.355 29.973C218.684 29.8218 218.107 29.5619 217.621 29.1977C217.133 28.8334 216.756 28.3456 216.492 27.7385C216.226 27.1293 216.092 26.3774 216.092 25.4785C216.092 24.5796 216.228 23.817 216.503 23.2291C216.778 22.6433 217.185 22.1832 217.723 21.8488C218.265 21.5143 218.942 21.2822 219.756 21.1522C220.569 21.0223 221.517 20.9584 222.597 20.9584H224.242C226.372 20.9584 228.008 21.1756 229.149 21.6102C230.291 22.0447 230.862 22.7775 230.862 23.8127V25.5914L226.983 26.5052V24.6094H220.661V26.5499H225.317C226.199 26.5499 226.992 26.633 227.69 26.8013C228.391 26.9696 228.987 27.2379 229.482 27.6107C229.976 27.9835 230.353 28.4628 230.613 29.0485C230.871 29.6343 231 30.3458 231 31.1829C231 32.1266 230.866 32.8955 230.6 33.4877C230.334 34.0799 229.929 34.5485 229.39 34.8914C228.851 35.2323 228.174 35.4687 227.36 35.5986C226.546 35.7286 225.592 35.7925 224.495 35.7925H222.303L222.301 35.7946Z"
      fill="black" />
  </svg>

)