import { v4 as uuid } from "uuid";
import React, { useEffect, useState } from "react";
import { MainWebsiteWrapper } from "layout/mainWebsiteWrapper";
import { GlobalStyles } from "assets/globalStyles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NewWebsiteRoutes, WebsiteRoutes } from "routes/routes.js";
import { PrivateRoute } from "routes/privateRoutes";
import { ScrollToTop } from "components/scrollToTop";
import i18n from "i18next";
import ReactGA from 'react-ga4';
import { initReactI18next } from "react-i18next";
import translationEN from "locales/en/translations.json";
import translationRU from "locales/ru/translations.json";
import translationAM from "locales/am/translations.json";
import translationZH from "locales/zh/translations.json";
import { GoBack } from "components/goBack";
import { CallToCompany } from "components/callToCompany";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { language } from "./utils/helpers";

// Separate component for handling side effects
const ScrollAndStorageEffects = () => {
  const [scrollState, setScrollState] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("lang"));

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= 800) {
        setScrollState(true);
      } else {
        setScrollState(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (language == null) {
      localStorage.setItem("lang", "am");
    }
  }, [language]);

  return null;
};

export const App = () => {
  const TRACKING_ID = 'G-YD7M8Z2F7J';
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview" });

  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: translationEN
        },
        ru: {
          translation: translationRU
        },
        am: {
          translation: translationAM
        },
        zh: {
          translation: translationZH
        }
      },
      lng: language,
      fallbackLng: "am",
      interpolation: {
        escapeValue: false
      }
    });

  return (
    <>
      <GlobalStyles />
      <Router>
        <MainWebsiteWrapper>
          <Routes>
            {NewWebsiteRoutes.map((item) => (
                  <Route key={uuid()} element={item.component} path={item.path} name={item.name} />
            ))}
          </Routes>
          <ToastContainer />
        </MainWebsiteWrapper>
      </Router>
    </>
  );
};
