import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DefaultFont } from "../../utils/defaultFont";

export const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 10px;  
  gap: 18px;
`;

export const AboutUsBlock = styled.section``;

export const AboutUsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    max-width: 1180px;
  }
  
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AboutUsContent = styled.div`
  border-bottom: 2px solid ${COLORS.main_color};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media screen and (max-width: 795px) {
    flex-direction: column;
  }
`;

export const OurTeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  padding-top: 10px;
  border-bottom: 2px solid ${COLORS.main_color};
`;

export const OurTeamText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 48px;
  color: ${COLORS.main_color};
  margin-bottom: 10px;
    
    @media only screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export const OurTeamCards = styled.div`
display: grid;
grid-template-columns: repeat(5, 1fr);
gap: 10px;
width: 100%;
padding-bottom: 10px;

@media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 428px) {
    gap: 4px;
    box-sizing: border-box;
}`;

export const TeamLeadCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 360px;
`;

export const OurTeamLeadCard = styled.div`
  position: relative;
  width: 300px;
  height: 350px;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 16px;
  transition: 0.4s;
  
  &:hover .hover{
    top: 0;
    display: flex;
    background: rgba(35, 38, 35, 0.7);
    flex-direction: column;
    border-radius: 16px;
    gap: 10px;
  }
  
  @media only screen and (max-width: 428px) {
    width: 100%;
    height: 300px;
  }
`;

export const OurWorkerImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

export const OurWorkerCard = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const OurWorkerCardDescriptionBlock = styled.div`
  padding: 20px;
  box-sizing: border-box;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(35, 38, 35, 0);
  position: absolute;
  top: 100%;
  transition: 0.3s;
  transition-delay: 0.2s;
  transition-timing-function: linear;
`;

export const WorkerPosition = styled.span`
  color: #032022;
  width: 100%;
  font-size: 12px;
`;

export const WorkerDescription = styled.span`
  font-size: 18px;
  color: ${COLORS.white};
`;

export const AboutUsInfoText = styled.p`
  font-size: 20px;
  color: ${COLORS.black};
  margin: 0 0 15px 0;
  word-break: break-all;

  p {
    @media only screen and (max-width: 420px) {
      font-size: 16px;
      margin: 0 0 8px 0;

    }
  }
`;

export const AboutUsVideosBlock = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 20px 0;
  border-bottom: 2px solid ${COLORS.main_color};
  margin-bottom: 20px;
`;

export const AboutUsVideoDiv = styled.div`
  width: 168px;
  height: 100px;
  border: 1px solid ${COLORS.main_color};

  @media only screen and (max-width: 768px) {
    width: 200px;
    height: 120px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

export const ChangeCarChangeTheWorld = styled.div`
    width: 100%;
    height: 350px;
    background: #e0f1ee;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
`;

export const ChangeCarChangeTheWorldText = styled.span`
    color: #008900;
    font-size: 30px;
    font-weight: bold;
    ${DefaultFont};
    text-transform: uppercase;
`;

export const ChangeCarTheWorldTextAbout = styled.span`
    color: #000000;
    font-size: 16px;
    padding-inline: 10px;
    text-align: center;
    font-weight: bold;
`;

export const WhyToChooseEcoMotors = styled.div`
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    justify-content: center;
    align-items: center;
    background: #032022;
    border-radius: 16px;
`;

export const TitleForEcoMotors = styled.span`
    font-size: 21px;
    color: #4FEC00;
`;

export const TitlesForUl = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TitlesForLi = styled.li`
    color: #E9F1EF;
    font-size: 16px;
`;
