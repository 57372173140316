import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AddressesBlock, AddressImage,
  EveryAddressContainer,
  EveryAddressTitle,
  Iframe,
  OurAddressesTitle,
  OurAddressesWrapper
} from "containers/ourAdresses/styled";
import { Carsheader } from "../../components/cars/carsheader";
import { Images } from "../../assets";

export const OurAdresses = () => {
  const { t } = useTranslation();

  return (
    <OurAddressesWrapper>
      <Carsheader titleText={t("our_addresses")} />
      <OurAddressesTitle>{t("our_addresses")}</OurAddressesTitle>
      <AddressesBlock>
        <EveryAddressContainer>
          <EveryAddressTitle>1. Ք. Երևան,Մելքումով 88</EveryAddressTitle>
          <AddressImage src={Images.melkumov}/>
        </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>2. Երևան- Սևան մայրուղի, թիվ 14</EveryAddressTitle>
          <AddressImage src={Images.sevan}/>
        </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>3. Արմավիրի մ, գ Փարաքար,Գևորգ Չաուշի 2</EveryAddressTitle>
          <AddressImage src={Images.ejmiatsin}/>
        </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>4. Նոր-Նորք , Դավիթ Բեկի 97/26</EveryAddressTitle>
          <AddressImage src={Images.davitBek}/>
        </EveryAddressContainer>
        <EveryAddressContainer>
          <EveryAddressTitle>5. Աջափնյակ Լուկաշինի 3-րդ փողոց թիվ 3/71</EveryAddressTitle>
          <AddressImage src={Images.ajapnyak}/>
        </EveryAddressContainer>
      </AddressesBlock>
    </OurAddressesWrapper>
  )
}