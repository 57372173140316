import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors.js";
import { DefaultFont } from "utils/defaultFont.js";
import { Swiper } from "swiper/react";
import { Link } from "react-router-dom";
import { Images } from "assets";
import Select from "react-select";
import ecoEvocaSmall from "assets/png/eco-evoca-786.jpg";
import mitsubishiMainFront from "assets/360Images/mitsubishi/mainfront.jpg";
import mitsubishiMainBack from "assets/360Images/mitsubishi/mainBack.png";

export const SwiperSliderImage = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 700px !important;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  
  @media only screen and (max-width: 1280px) {
    height: 500px !important;
  }
  
  @media only screen and (max-width: 428px) {
    height: 360px;
  }
`;

export const WrapperSlider = styled.div`
  .swiper {
    height: 700px;

    @media only screen and (max-width: 1280px) {
      height: 500px !important;
    }

    @media only screen and (max-width: 428px) {
      height: 360px;
    }
  }
`;

export const StyledSearchBlockWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #032022;
  width: 100%;
  gap: 40px;
  border-radius: 5px;
  height: 160px;
  box-sizing: border-box;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  padding-inline: 50px;

  @media only screen and (max-width: 768px) {
    padding-top: 10px;
    flex-direction: column;
    z-index: 9999;
    border-bottom: 2px solid black;
    margin-top: unset;
    margin-bottom: unset;
      height: auto;
  }
`;

export const SearchBlock = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1280px) {
    height: auto;
  }
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 1;
  background: rgba(145, 198, 93, 0.3) none repeat scroll 0 0;
`;

export const RightSideFullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
`;


export const RightSideWithFilteration = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 5px;
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;


export const AboutCarValue = styled.span`
  color: ${COLORS.black};
  font-size: 14px;
  font-weight: 800;
  padding-right: 10px;
  ${DefaultFont};
  text-transform: capitalize;
  word-break: break-all;

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }

  @media only screen and (max-width: 428px) {
    font-size: 2vw;
  }
`;

export const AboutCarKey = styled.span`
  color:  ${COLORS.black};
  font-size: 14px;
  padding-right: 10px;
  font-weight: 900;
  ${DefaultFont};
  text-transform: capitalize;

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }
  
  @media only screen and (max-width: 428px) {
    font-size: 2vw;
  }
`;

export const CarContentBlock = styled.div`
  width: 100%;
  height: 100%;
`;

export const CarContentImage = styled.img`
  width: 100%;
  height: 10%;
  object-fit: cover;
`;

export const OptionInputFilter = styled.option`
  width: 100%;
  font-size: 15px;
  color: ${COLORS.main_color};
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom: unset;
  }
`;

export const CheckboxesMainParent = styled.div`
  display: flex;
  gap: 24px;
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        
    }
`;

export const EveryCheckBoxWithText = styled.div`
  height: 20px;
  color: ${COLORS.main_color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;

  @media only screen and (max-width: 1280px) {
    font-size: 15px;
  }

    @media only screen and (max-width: 768px) {
        justify-content: left;
        gap: 10px;

    }
`;

export const CheckBox = styled.input`
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid ${COLORS.main_color};
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:checked {
    border-color: ${COLORS.main_color};
    background-color: ${COLORS.main_color};
  }

  &:focus {
    border-color: ${COLORS.main_color};
    box-shadow: 0 0 0 3px rgba(${COLORS.white}, 0.3);
  }
`;

export const Label = styled.label`
  color: ${COLORS.main_color};
  font-size: 14px;
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 180px;
  height: 40px;
  background-color: ${COLORS.main_color};
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 22px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.8s;
  color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 1280px) {
    font-size: 1.2vw;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 3.2vw;
  }

  @media only screen and (max-width: 428px) {
    font-size: 4.2vw;
  }
`;

export const TopAnnouncmentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const TopAnnouncmentBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: ${COLORS.white};
  gap: 30px;
    
    @media only screen and (max-width: 768px) {
        gap: 0;
    }
`;

export const AvailableCarsBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: auto;
  gap: 30px;
    
    @media only screen and (max-width: 768px) {
        gap: 0;
    }
`;

export const TwoButtons = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  
  .chevron {
    border: 2px solid ${COLORS.main_color};
    border-radius: 100%;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    color: ${COLORS.main_color};
    transition: 0.4s linear;
    
    &:hover {
      background: ${COLORS.main_color};
      color: ${COLORS.white};
    }
    
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const TopAnnouncmentText = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  color: #000000;
  font-size: 33px;
  font-weight: 500;
  text-transform: capitalize;
    
    @media only screen and (max-width: 768px) {
        font-size: 12px;
        
        svg {
            width: 20px;
        }
    }
`;

export const InvisibleH1 = styled.h1`
  position: absolute;
  z-index: -444444;
  display: none;
`;

export const AnnouncmentsWraper = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const CarMainBlockWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    justify-items: center;
    padding-inline: 10px;
    box-sizing: border-box;
    max-width: 100%;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 768px) {
        gap: 10px;
        padding-inline: 2px;
        grid-template-columns: repeat(2, 1fr);
    }

    a {
        width: 100% !important;
        text-decoration: none;
    }
`;


export const CarMainBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 236px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background: #e9f1ef;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: content-box; 

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    span {
        overflow: hidden;
    }

    span img {
        width: 100%;
        height: 160px;
        object-fit: cover;
    }
`;

export const CarAvailableBlock = styled.div`
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: auto;
    background: ${COLORS.main_color};
    color: ${COLORS.white} !important;
    border-radius: 0 0 0 25px;

    @media only screen and (max-width: 768px) {
        padding: 4px;
    }
`;

export const CarMainImage = styled.div`
    width: 100%;
    height: 176px;
    background: url(${({ backgroundImage }) => backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 428px) {
        height: 254px;
    }
`;

export const CarNameDateAndePrice = styled.div`
    display: flex;
    flex-direction: column;
    padding-inline: 12px;
    height: 72px;
    position: relative;
`;

export const CarNameForWelcome = styled.h2`
    color: #000000;
    text-align: left;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ isWelcome }) => (isWelcome ? "1" : "5")};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90% !important;
    height: unset !important;

    @media only screen and (max-width: 542px) {
        font-size: 11px !important;
    }
`;


export const CarName = styled.h1`
  color: ${COLORS.main_color};
  text-align: left;
  font-size: 17px;
  margin: 0;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ isWelcome }) => (isWelcome ? "1" : "5")};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90% !important;
  height: unset !important;

  @media only screen and (max-width: 542px) {
    font-size: 11px !important;
  }
`;

export const CarDateAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
`;

export const CarPriceAndDateText = styled.p`
  color: #008900 !important;
  font-size: 12px !important;
  ${DefaultFont} !important;
  font-family: Roboto, sans-serif;
  margin: 5px 0 0 0;
    font-weight: bold;

  @media only screen and (max-width: 542px) {
    font-size: 11px !important;
  }
`;

export const CarDateText = styled.span`
    color: #000000 !important;
    font-size: 10px !important;
    ${DefaultFont} !important;
    font-family: Roboto, sans-serif;
    position: absolute;
    top: -4px;
    right: 4px;
    margin: 5px 0 0 0;

    @media only screen and (max-width: 542px) {
        font-size: 11px !important;
    }
`;

export const GoToSeeMoreCars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const GoToSeeMoreCarsButton = styled.button`
    width: 162px;
    height: 28px;
    outline: none;
    border-radius: 14px;
    color: #000000;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-weight: 400;
    ${DefaultFont};
    font-size: 14px;
    cursor: pointer;
`;

export const VideoBlockWrapper = styled.div`
  padding-inline: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;

  @media only screen and (max-width: 1454px) {
    padding-inline: 120px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
  }
`;

export const VideoBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.main_color};
  border-radius: 4px;
  gap: 30px;
  padding-top: 20px;
  margin-bottom: 10px;

  @media only screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const VideoText = styled.span`
  color: ${COLORS.main_color};
  font-size: 3vw;

  @media only screen and (max-width: 768px) {
    font-size: 5vw;
  }
`;

export const SliderAdvertisementImage = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: 100%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    background: url(${ecoEvocaSmall});
    background-size: contain;
  }
`;

export const SliderAdvertisementImageAmeria = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    background: url(${Images.advertisementSmall});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const SliderAdvertisementImageFull = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: ${({isFull}) => isFull ? 'cover' : '100% 100%'};
  background-position: center;
`;

export const SliderAdvertisementInecoImage = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    background: url(${Images.ecoInecoSmall});
    background-size: contain;
    background-repeat: round;
  }
`;

export const ArmenianLeasingAdvertisementImage = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: 100% 100%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    background: url(${Images.ArmenianLeasingSmall});
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const SwiperSliderWrapper = styled.div`
  padding-inline: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  flex-direction: column;

  @media only screen and (max-width: 1454px) {
    padding-inline: 120px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
    padding-bottom: unset;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
  }
`;

export const SliderBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  width: 100%;
  height: 450px;
  background: ${COLORS.white};
  overflow: hidden;

  @media only screen and (min-width: 1920px) {
    height: 500px;
  }

  @media only screen and (max-width: 428px) {
    height: 350px;
  }

  .slider {
    display: flex;
    overflow-x: scroll;
  }

  .slide {
    flex-shrink: 0;
    width: 100%;
  }

  .swiper-wrapper {
    margin-top: 60px !important;
    justify-content: space-between;
      background: transparent;
  }

    .swiper-slide {
    background: transparent !important;
    }

  .swiper-button-next {
    position: absolute;
    top: 0;
    right: 0 !important;
    left: auto !important;
    margin: unset !important;
    background: url(${Images.nextSlide});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 45px;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    position: absolute;
    top: 0;
    right: 56px !important;
    left: unset !important;
    margin: unset !important;
    background: url(${Images.prevSlide});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 45px;
  }

  .swiper-button-prev::after {
    display: none;
  }
`;


export const SliderTitle = styled.h1`
  color: ${COLORS.main_color};
  animation: ${slideIn} infinite 6s ease-in-out;
  font-size: 3vw;
  margin: 14px 0 0 0;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
  @media only screen and (max-width: 515px) {
    font-size: 5vw;
  }
`;

export const MainSliderItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
  gap: 2px;
  border: 1px solid ${COLORS.main_color};
  width: 220px;
  height: 250px;
  cursor: pointer;

  @media only screen and (max-width: 1280px) {
    width: 200px;
    height: 240px;
  }

  @media only screen and (max-width: 768px) {
    width: 170px;
    height: 240px;
  }

  @media only screen and (max-width: 428px) {
    width: 150px;
    height: 200px;
    font-size: 14px;
  }

  .lazy-load-image-background {
    position: absolute;
    width: 100%;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 156px;
    object-fit: contain !important;

    @media only screen and (max-width: 428px) {
      height: 110px;
    }
  }
`;

export const LinkToCar = styled(Link)`
  color: ${COLORS.white};
  text-decoration: none;
  align-self: center;
  outline: none;
  background: ${COLORS.main_color};
  border: 1px solid ${COLORS.main_color};
  color: ${COLORS.white};
  font-size: 20px;
  width: 90%;
  height: 30px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;
  border-radius: 8px;
  position: absolute;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${COLORS.white};
    border: 1px solid ${COLORS.main_color};
    color: ${COLORS.main_color};
  }
`;

export const SwiperSliderParent = styled(Swiper)`
  width: 90%;

  .swiper-wrapper {
    width: 100% !important;
    overflow: unset !important;
      background: transparent;
      
  }
`;

export const SwiperSliderCarImage = styled.div`
  width: 100%;
  height: 150px !important;
  background: url(${({ backgroundImage }) =>
          backgroundImage ? backgroundImage : null});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
`;

export const LazyLoadLink = styled(Link)`
  span {
    position: unset !important;
  }
`;

export const CarNameWithGreen = styled.h1`
  width: 85%;
  padding-inline: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${COLORS.main_color};
  position: absolute;
  bottom: 70px;
  margin: 0;
  font-weight: 500;
  font-size: 17px;
`;

export const CarYearAndPriceBlock = styled.div`
  width: 98%;
  padding-inline: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 42px;
`;

export const CarPriceSlideBlock = styled.div`
  padding-inline: 10px;
  display: flex;
  justify-content: space-between;
  color: ${COLORS.main_color};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CarYearAndPriceText = styled.span`
  color: ${COLORS.main_color};
`;

export const CarTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  min-height: 42px;
  width: 100%;
  margin-bottom: 4px;
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    overflow-x: auto;
    padding-inline: 10px;
    white-space: nowrap;
    height: auto;
    padding-bottom: 10px;
    gap: 10px;
  }
`;

export const CarTypeText = styled.span`
  font-size: 1.2vw;
  color: black;
  font-weight: bold;
  transition: 0.3s linear;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;

  @media only screen and (max-width: 968px) {
    font-size: 1.8vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 2.2vw;
  }

  @media only screen and (max-width: 515px) {
    font-size: 3.2vw;
  }

  @media only screen and (max-width: 428px) {
    font-size: 4.2vw;
  }
`;

export const CarTypeBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 30px;
  border-bottom: 1px solid ${COLORS.main_color};
  border-bottom: 4px;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  cursor: pointer;
  border-radius: 4px;
 
  @media only screen and (max-width: 768px) {
    width: ${({isWidthFull}) => isWidthFull ? '100%' : '45%'};
    justify-content: ${({isWidthFull}) => isWidthFull ? 'center' : 'start'};
    padding-inline: 20px;
    box-sizing: border-box;
  }
`;

export const CarIcon = styled.img`
  width: 30px;
`;

export const SelectField = styled(Select)`
  width: 15% !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  font-size: 12px;
  position: relative;
  color: #FFFFFF;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }

  span {
    display: none;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
    color: rgba(255, 255, 255, 0.5);
  }

  .css-1dimb5e-singleValue {
    color: rgba(255, 255, 255, 0.5);
  }

  div {
    border-style: unset !important;
    padding: 1px !important;
  }

  #react-select-2-listbox,
  #react-select-3-listbox, 
  #react-select-4-listbox,
  #react-select-5-listbox, 
  #react-select-6-listbox,
  #react-select-7-listbox {
      background: rgba(255, 255, 255, 0.5) !important;
  }
`;

export const Styled360CameraWrapper = styled.div`
  padding-inline: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  flex-direction: column;
  margin-bottom: 10px;

  @media only screen and (max-width: 1454px) {
    padding-inline: 120px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
    padding-bottom: unset;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
  }
`;

export const Styled360CameraContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  background: ${COLORS.white};
  padding-bottom: 20px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`;

export const Styled360CameraContentTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  width: 100%;
  height: 80px;
  color: ${COLORS.main_color};
  ${DefaultFont};
  animation: ${slideIn} infinite 3s ease-in-out;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }
`;

export const Styled360CamerasWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-inline: 10px;
  box-sizing: border-box;
  gap: 30px;
  
  @media only screen and (max-width: 928px) {
    flex-direction: column;
  }
`;

export const Styled360CameraBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 300px;
`;

export const Styled360CameraBlockID6 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 300px;
`;

export const Styled360CameraBlock = styled.div`
  width: 300px;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-image: url(${Images.ID6Front});
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    background-image: url(${Images.ID6Back});
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

export const StyledID6360CameraBlock = styled.div`
  width: 300px;
  height: 280px;
  cursor: pointer;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-image: url(${Images.ID4Front});
  background-size: 380px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-bottom: 20px;

  &:hover {
    background-image: url(${Images.ID4Back});
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

export const StyledEQS360CameraBlock = styled.div`
  width: 300px;
  height: 280px;
  cursor: pointer;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-image: url(${Images.eqsFront});
  background-size: 380px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-bottom: 20px;

  &:hover {
    background-image: url(${Images.eqsBack});
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

export const StyledHondaENS1360CameraBlock = styled.div`
  width: 300px;
  height: 300px;
  cursor: pointer;
  background-size: 150%;
  padding-right: 50px;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-color: #f7f7f8;
  background-image: url(${Images.HondaEcoFront});
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &:hover {
    background-image: url(${Images.HondaEco});
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

export const EightBlogWrapper = styled.div`
  padding-inline: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: column;

  @media only screen and (max-width: 1454px) {
    padding-inline: 120px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
    padding-bottom: unset;
  }
`;

export const EightBlogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background: ${COLORS.white};
`;

export const EightBlogsContainerTitle = styled.span`
  font-weight: 700;
  color: ${COLORS.main_color};
  text-transform: capitalize;
  width: 100%;
  height: 60px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media only screen and (max-width: 768px) {
    height: 40px;
    font-size: 28px;
  }

  @media only screen and (max-width: 428px) {
    height: 40px;
    font-size: 5.4vw;
  }
`;

export const EightBlogsMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  
  @media only screen and (max-width: 928px) {
    justify-content: center;
  }
`;

export const IntroWrapper = styled.div`
  padding-inline: 200px;
  padding-top: 40px;
  padding-bottom: 20px;
  box-sizing: border-box;
  
  @media only screen and (max-width: 1454px) {
    padding-inline: 120px;
  }

  @media only screen and (max-width: 1280px) {
    padding-inline: 80px;
    padding-bottom: unset;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 20px;
  }
`;

export const InfoChild = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  background: ${COLORS.black};
  
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 768px) {
    height: 240px;
  }
`;

export const MarqueeEveryBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  border-radius: 30px;
  border: 3px solid ${COLORS.main_color};
  margin-left: 10px;
  margin-right: 10px;
  color: ${COLORS.white};
  transition: 0.3s;
  transition-delay: 0.3s;
  transition-timing-function: linear;
  background: transparent;
  
  &:hover {
    border: 3px solid ${COLORS.white};
    color: ${COLORS.white};
  }

  @media only screen and (max-width: 768px) {
    width: 120px;
    height: 40px;
    font-size: 10px;
  }
`;

export const BlockTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.black};
  padding: 40px;
  box-sizing: border-box;
  ${DefaultFont};
  width: 100%;
  height: 150px;
`;

export const BlockImage = styled.img`
  width: 150px;
  
  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

export const BlockTitle = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  color: ${COLORS.white};
  transition: 0.3s;
  transition-delay: 0.3s;
  transition-timing-function: ease-in-out;

  &:hover {
    color: ${COLORS.main_color};
  }
  
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const StyledBZ4XCameraBlock = styled.div`
  width: 300px;
  height: 280px;
  cursor: pointer;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-image: url(${Images.bz4xFront});
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-bottom: 20px;

  &:hover {
    background-image: url(${Images.bz4xBack});
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

export const StyledMitsubishiCameraBlock = styled.div`
  width: 300px;
  height: 280px;
  cursor: pointer;
  border: 1px solid ${COLORS.main_color};
  border-radius: 20px;
  background-image: url(${mitsubishiMainFront});
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  padding-bottom: 20px;

  &:hover {
    background-image: url(${mitsubishiMainBack});
    background-size: 110%;
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;
