import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { selectCars, selectCarStatus } from "store/cars/selector";
import { ROUTENAMES } from "routes/routeNames.js";
import { IMAGE_STARTING_URL, REDUX_STATES, SELECTED_TYPES } from "utils/constants";
import { carModels } from "store/carModels/actions";
import { cars } from "store/cars/actions";
import { useTranslation } from "react-i18next";
import { carFilterCountry } from "store/carFilterCountry/actions";
import { selectCarFilterCountry } from "store/carFilterCountry/selector";
import { carNames } from "store/carNames/actions";
import { NumberWithDots } from "HOC/numberWithDots";
import { selectCurrencyExchange } from "store/currencyExchange/selector";
import { currencyExchange } from "store/currencyExchange/action";
import { country } from "store/country/action";
import { CarLeftSideBlock } from "components/cars/carLeftSideBlock";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  CarBlock,
  CarDateEndineDrive,
  CarImage,
  CarMainInformation,
  CarModelDate,
  CarModelName,
  CarPrice,
  CarRow,
  CarsContentWrapper,
  CarStatement,
  CarsWrapper,
  RightCarSection
} from "components/cars/styled.js";
import { CarsList } from "./carsList";
import { carWithArticulate } from "../../store/carSinglePage/actions";
import { CarAvailableBlock } from "../welcome/styled";
import { AvailableCarsForSingle } from "../availableCarsForSingle";
import {
  ActiveCarsAndWithOrder,
  ActiveCarsText,
  ContentTitle,
  ContentTitleWithAllText, ContentTitleWithTypes, WithOrderText
} from "../../containers/availableCars/styled";

export const CarsContent = ({ search }) => {
  const [selectedType, setSelectedType] = useState(SELECTED_TYPES.CONTAINER);
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const [selectedCarData, setSelectedCarData] = useState({});
  const { t } = useTranslation();
  const [carName, setCarName] = useState({
    value: t("car_name"),
    label: t("car_name")
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const SelectCarData = useSelector(selectCars);
  const isFilteredDataAvailable = searchParams.get("filteredData");
  const isFilterCountryAvailable = searchParams.get("id");

  console.log(isFilterCountryAvailable, isFilteredDataAvailable);

  useEffect(() => {
    setCarName(selectedCarData.car_name_id);
  }, [selectedCarData]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setSelectedType(SELECTED_TYPES.CONTAINER);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isFilterCountryAvailable) {
      navigate(
        `${ROUTENAMES.cars}?filterCountry=true&id=${isFilterCountryAvailable}`
      );
    }
  }, [isFilterCountryAvailable]);

  useEffect(() => {
    if(search) {
      dispatch(carWithArticulate({ search, language: "am" }));
    } else {
      if (!isFilteredDataAvailable && !isFilterCountryAvailable) {
        dispatch(cars({ is_filter: 0, lang: language }));
      } else if (isFilterCountryAvailable) {
        dispatch(carFilterCountry(isFilterCountryAvailable));
      } else {
        const FilteredDataWithParsing = JSON.parse(isFilteredDataAvailable);
        setSelectedCarData(FilteredDataWithParsing);
        const AssignedObject = (Object.assign(FilteredDataWithParsing, { lang: language }));
        dispatch(cars(AssignedObject));
      }
    }
  }, [dispatch, language, isFilterCountryAvailable, search]);

  useEffect(() => {
    dispatch(carModels());
  }, [dispatch]);

  useEffect(() => {
    dispatch(country(language));
  }, [language]);

  useEffect(() => {
    if (carName !== "") {
      dispatch(carNames({ car_name_id: carName }));
    }
  }, [carName]);

  return (
    <CarsContentWrapper>
      <ContentTitleWithTypes>
        <ContentTitleWithAllText>
          <ContentTitle>{t("Cars")}</ContentTitle>
          {t("all_cars")}
        </ContentTitleWithAllText>
      </ContentTitleWithTypes>
      <AvailableCarsForSingle filteredCars={SelectCarData} />
    </CarsContentWrapper>
  );
};