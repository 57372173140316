import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCarWithTypeSlug, selectCarWithTypeSlugStatus } from "store/carWithTypeSlug/selector";
import { carWithTypeSlug } from "store/carWithTypeSlug/actions";
import { REDUX_STATES } from "utils/constants";
import { useTranslation } from "react-i18next";
import { Loading } from "components/loading";
import {
  BlogAnimatedTitle,
  CarWithTypeContainer,
  CarWithTypeMainWrapper,
  CarWithTypeWrapper
} from "containers/carWithType/styled";
import { AvailableCarsForSingle } from "../../components/availableCarsForSingle";

export const CarWithType = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectCarWithSlugData = useSelector(selectCarWithTypeSlug);
  const selectCarWithSlugDataStatus = useSelector(selectCarWithTypeSlugStatus);
  const [language, setLanguage] = useState(localStorage.getItem("lang"));

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(carWithTypeSlug({
      slug: slug,
      language: language
    }));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  return (
    <React.Fragment>
      {selectCarWithSlugDataStatus === REDUX_STATES.PENDING ? <Loading /> : <CarWithTypeMainWrapper>
        <CarWithTypeContainer>
          <BlogAnimatedTitle>{+slug === 1 ? t("sedan") : +slug === 3 ?
            t("crossover") : +slug === 4 ? t("truck") : +slug === 5 ?
              t("minivan") : +slug === 6 ? t("hatchback") : t("cars")}</BlogAnimatedTitle>
        </CarWithTypeContainer>
        <CarWithTypeWrapper>
          <AvailableCarsForSingle filteredCars={selectCarWithSlugData} />
        </CarWithTypeWrapper>
      </CarWithTypeMainWrapper>}
    </React.Fragment>
  );
};