import styled from "styled-components";

export const LayoutForNewDesignWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 800px;
`;

export const LeftLayout = styled.div`
  display: flex;
  flex-direction: column;  
  width: 250px;
  gap: 10px;
  height: auto;
  
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LayoutImg = styled.img`
  width: 100px;
  height: 330px;
`;

export const GifAuth = styled.img`
    width: 100%;
`;

export const MainLayout = styled.div`
  width: 100%;
  height: 100%;
`;

export const TypesOfCarWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%;
  height: 42px;
  background: #008900;
  gap: 10px;
  overflow-x: hidden;

  @media only screen and (max-width: 447px) {
        overflow-x: auto;
        padding-inline: 10px;
        white-space: nowrap; 
      justify-content: left;
  }
`;

export const TypesOfCarText = styled.span`
  color: #FFFFFF;
  cursor: pointer;

  @media only screen and (max-width: 1100px) {
    font-size: 10px;
  }
`;
