import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCars } from "store/cars/selector";
import { ROUTENAMES } from "routes/routeNames";
import { topCars } from "store/topAnnouncments/actions";
import "components/welcome/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { Loading } from "components/loading";
import { useTranslation } from "react-i18next";
import { selectTopCars, selectTopCarStatus } from "store/topAnnouncments/selector";
import { NumberWithDots } from "HOC/numberWithDots";
import { currencyExchange } from "store/currencyExchange/action";
import { selectCurrencyExchange } from "store/currencyExchange/selector";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  AnnouncmentsWraper, CarAvailableBlock,
  CarDateAndPrice, CarDateText,
  CarMainBlock,
  CarMainBlockWrapper,
  CarNameDateAndePrice, CarNameForWelcome,
  CarPriceAndDateText,
  GoToSeeMoreCars,
  GoToSeeMoreCarsButton, InvisibleH1,
  TopAnnouncmentBlock,
  TopAnnouncmentsWrapper,
  TopAnnouncmentText
} from "components/welcome/styled.js";
import { MiniEcoMotors } from "../../assets/svgFiles/miniEcoMotors";

export const TopAnnouncements = ({ myRef }) => {
  const [language, setLanguage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SelectTopCarData = useSelector(selectTopCars);
  const { t } = useTranslation();
  const SelectCarDataStatus = useSelector(selectTopCarStatus);
  const carMainBlockRef = useRef(null);

  useEffect(() => {
    if (SelectTopCarData.length) {
      return selectCars;
    } else {
      dispatch(topCars({ language }));
    }
  }, [dispatch, language]);

  return (
    <TopAnnouncmentsWrapper ref={myRef}>
      <TopAnnouncmentBlock>
        <TopAnnouncmentText><MiniEcoMotors /> {t("topAnnouncments")}</TopAnnouncmentText>
        <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
          Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>

        <AnnouncmentsWraper>
          <CarMainBlockWrapper ref={carMainBlockRef}>
            {SelectCarDataStatus === REDUX_STATES.PENDING ?
              <Loading /> : SelectTopCarData.slice(0, 12).map((element) => (
                <Link className="main__link" to={`${ROUTENAMES.car}/${element.car_slug}`} target={"_blank"}
                      key={element.id}>
                  <CarMainBlock
                    key={element.car_slug}
                    >
                    {
                      element?.product_images.map((item) => {
                        return item?.main_image ? <LazyLoadImage
                          key={item.id}
                          effect="blur"
                          alt="main car image"
                          src={item?.main_image ? `${IMAGE_STARTING_URL}/${item?.image}` : null}
                        /> : null;
                      })
                    }
                    <CarNameDateAndePrice>
                      <CarNameForWelcome
                        isWelcome>{element.product_name.car_name} {element.product_model.model_name}</CarNameForWelcome>
                      <CarDateAndPrice>
                        <CarPriceAndDateText>
                          {NumberWithDots(element.car_price)} $
                        </CarPriceAndDateText>
                        <CarDateText>{element.product_year.car_year}</CarDateText>
                      </CarDateAndPrice>
                    </CarNameDateAndePrice>
                  </CarMainBlock>
                </Link>
              ))}
          </CarMainBlockWrapper>
          <GoToSeeMoreCars>
            <GoToSeeMoreCarsButton
              onClick={() => navigate(ROUTENAMES.cars)}>{t("goToSeeMoreCars")}</GoToSeeMoreCarsButton>
          </GoToSeeMoreCars>
        </AnnouncmentsWraper>
      </TopAnnouncmentBlock>
    </TopAnnouncmentsWrapper>
  );
};

