import {
  GifAuth,
  LayoutForNewDesignWrapper,
  LayoutImg,
  LeftLayout,
  MainLayout,
  TypesOfCarText,
  TypesOfCarWrapper
} from "./styled";
import { Images } from "../../assets";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";

export const LayoutForNewDesign = ({children}) => {
  const navigate = useNavigate();

  return (
    <LayoutForNewDesignWrapper>
      <LeftLayout>
        <div style={{display: "flex", gap: "10px"}}>
          <LayoutImg src={Images.left1}/>
          <LayoutImg src={Images.left2}/>
        </div>

        <GifAuth src="https://auto.am/static/azd/345/c22c1e9dc43433a6766b3a3a7f90daf4.gif" />
      </LeftLayout>
      <MainLayout>
        <TypesOfCarWrapper>
          <TypesOfCarText onClick={() => navigate(ROUTENAMES.available_cars)}>Առկա Մեքենաներ</TypesOfCarText>
          <TypesOfCarText>|</TypesOfCarText>
          <TypesOfCarText onClick={() => navigate(ROUTENAMES.cars)}>Մեքենաներ</TypesOfCarText>
          <TypesOfCarText>|</TypesOfCarText>
          {/*<TypesOfCarText>Ավտոպահեստամասեր</TypesOfCarText>*/}
          {/*<TypesOfCarText>|</TypesOfCarText>*/}
          <TypesOfCarText onClick={() => navigate(ROUTENAMES.motorcycles)}>Մոտոցիկլետներ</TypesOfCarText>
          <TypesOfCarText>|</TypesOfCarText>
          <TypesOfCarText onClick={() => navigate(ROUTENAMES.active_motorcycles)}>Առկա Մոտոցիկլներներ</TypesOfCarText>
        </TypesOfCarWrapper>
        {children}
      </MainLayout>
      <LeftLayout>
        <div style={{ display: "flex", gap: "10px" }}>
          <LayoutImg src={Images.right1} />
          <LayoutImg src={Images.right2} />
        </div>

        <GifAuth src={Images.dongfengGif} />
      </LeftLayout>
    </LayoutForNewDesignWrapper>
  )
}