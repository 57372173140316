  import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CarSpecialPageSlider } from "components/cars/carSpecialPageSlider.js";
import { useDispatch, useSelector } from "react-redux";
import { carSlug } from "store/carSinglePage/actions";
import { selectCarSlug, selectCarStatus } from "store/carSinglePage/selector";
import { Loading } from "components/loading";
import { websiteMainData } from "store/websiteMainData/actions";
import { useTranslation } from "react-i18next";
import { NumberWithDots } from "HOC/numberWithDots";
import { currencyExchange } from "store/currencyExchange/action";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { NotFound } from "containers";
import { resetStatus } from "store/favourite/slice";
import { MdElectricBolt } from "react-icons/md";
import { MdDone } from "react-icons/md";
import {
  AboutAHref,
  CallToPartner,
  CallToPartnerSilver,
  CarName,
  CarPageCarPriceRight,
  CarPageContent,
  CarPageLeftSide,
  CarPageWrapper, CarPriceAndNameWrapper,
  CarSpecialPageSliderLeft,
  CarSpecialPagesSliderRight,
  EveryLineOfCarCharacteristics,
  InfoAboutAHref,
  InfoAboutAHrefBlack,
  ItemForCarInfo,
  MainBlocksWrapper,
  MainInfoAboutCar,
  SimpleLine,
} from "containers/carPage/styled.js";
import { FaPhoneAlt } from "react-icons/fa";
import { InvisibleH1 } from "../../components/welcome/styled";
import { IoMdSettings } from "react-icons/io";


  export const CarPage = () => {
  const [language, setLanguage] = useState("am");
  const location = useLocation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
    const carSelectedData = useSelector(selectCarSlug);
  const carSelectedStatus = useSelector(selectCarStatus);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    dispatch(websiteMainData(language));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(carSlug({ slug }));
  }, [dispatch, language, location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    dispatch(resetStatus());
  }, [slug]);

  useEffect(() => {
    const title = carSelectedData?.product_name?.car_name + " " + carSelectedData?.product_model?.model_name;
    const imageUrl = `${IMAGE_STARTING_URL}/${carSelectedData?.product_images?.[0]?.image}`;

    const setMetaTags = () => {
      const metaTags = [
        { property: "og:title", content: title },
        { property: "og:image", content: imageUrl }
      ];

      metaTags.forEach((tag) => {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("property", tag.property);
        metaTag.setAttribute("content", tag.content);
        document.head.appendChild(metaTag);
      });
    };

    setMetaTags();

    return () => {
      const metaTags = document.querySelectorAll("meta[property^=\"og:\"]");
      metaTags.forEach((tag) => {
        document.head.removeChild(tag);
      });
    };
  }, [carSelectedData]);

  return (
    <>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
      <CarPageWrapper>
        {carSelectedData?.length === 0 ||
        carSelectedStatus === REDUX_STATES.PENDING ? (
          <Loading />
        ) : carSelectedData == null ? (
          <NotFound />
        ) : (
          <CarPageContent>
            <CarPriceAndNameWrapper>
              <CarName>{carSelectedData?.product_name?.car_name} {carSelectedData?.product_model?.model_name}</CarName>
              <CarPageCarPriceRight>{NumberWithDots(+carSelectedData.car_price)} $</CarPageCarPriceRight>
            </CarPriceAndNameWrapper>
            <CarPageLeftSide>
              <CarSpecialPageSliderLeft>
                <CarSpecialPageSlider
                  carData={carSelectedData}
                />
              </CarSpecialPageSliderLeft>
              <CarSpecialPagesSliderRight>
                <CallToPartner href={`tel:+374 95 94 94 66`}>
                  <FaPhoneAlt />
                  <InfoAboutAHref>
                    <AboutAHref>+374 95 94 94 66 </AboutAHref>
                    <AboutAHref>Մանրամասների համար</AboutAHref>
                  </InfoAboutAHref>
                </CallToPartner>

                <CallToPartnerSilver href={`tel:+374 95 94 94 66`}>
                  <MdElectricBolt />
                  <InfoAboutAHrefBlack>
                    <AboutAHref>Գրանցվել Տեստ Դրայվի</AboutAHref>
                  </InfoAboutAHrefBlack>
                </CallToPartnerSilver>

                <CallToPartnerSilver href={`tel:+374 95 94 94 66`}>
                  <IoMdSettings style={{ color: "orange" }}/>
                  <InfoAboutAHrefBlack>
                    <AboutAHref>Պահեստամասեր և դետալներ</AboutAHref>
                  </InfoAboutAHrefBlack>
                </CallToPartnerSilver>
              </CarSpecialPagesSliderRight>
            </CarPageLeftSide>

            <MainInfoAboutCar>Հիմնական Բնութագիր</MainInfoAboutCar>
            <MainBlocksWrapper>
              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>{t("country")}
                  <span>{carSelectedData?.product_country_price?.[0]?.name_country ? carSelectedData?.product_country_price?.[0]?.name_country : '-'}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
                <EveryLineOfCarCharacteristics>{t("car_product_year")}
                  <span>{carSelectedData?.product_year?.car_year}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_motor")}
                  <span>{carSelectedData?.product_motor?.car_motor} KW</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_horsepower")}
                  <span>{carSelectedData?.car_horsepower}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_cruising_range")}
                  <span>{carSelectedData?.car_cruising_range}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_max_speed")}
                  <span>{carSelectedData?.car_max_speed}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />


                <EveryLineOfCarCharacteristics>{t("car_official")}
                  <span>{carSelectedData?.car_official}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_tugboat")}
                  <span>{carSelectedData?.product_tugboat?.car_tugboat}</span>
                </EveryLineOfCarCharacteristics>
              </ItemForCarInfo>

              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>{t("car_torque")}
                  <span>{carSelectedData?.car_torque}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_battery_capacity")}
                  <span>{carSelectedData?.car_battery_capacity} կվտ/ժ</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_battery_energy_density")}
                  <span>{carSelectedData?.car_battery_energy_density} (վտ/կգ)</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_wheel_size")}
                  <span>{carSelectedData?.car_wheel_size}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_wheel_size_back")}
                  <span>{carSelectedData?.car_wheel_size_back}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_doors")}
                  <span>{carSelectedData?.car_doors}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />


                <EveryLineOfCarCharacteristics>{t("car_seat_count_id")}
                  <span>{carSelectedData?.product_seat_count?.seat_count}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />


                <EveryLineOfCarCharacteristics>{t("car_length_width_height")}
                  <span>{carSelectedData?.car_length_width_height} մմ</span>
                </EveryLineOfCarCharacteristics>
              </ItemForCarInfo>


              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>{t("car_choice_of_driving_modes")}
                  <span>{carSelectedData?.car_choice_of_driving_modes}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_navigation_system")}
                  <span>{carSelectedData?.car_navigation_system} </span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_parking_radar")}
                  <span>{carSelectedData?.car_parking_radar}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("car_type")}
                  <span>{carSelectedData?.product_body?.car_body_name}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>Սրահի Մատերիալ
                  <span>{carSelectedData?.product_type_salon?.car_type_salon}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                {/**/}

                {carSelectedData?.car_abs_anti_lock_system ?
                  <>

                  <EveryLineOfCarCharacteristics>ՀԱԿԱ-ABS-ի կողպեք
                    <span>ՍՏԱՆԴԱՐՏ</span>
                  </EveryLineOfCarCharacteristics>
                  <SimpleLine />

                  </>: null}


                {carSelectedData?.car_brake_force_distribution_ebd_cbc ?
                  <>

                    <EveryLineOfCarCharacteristics>մեքենայի արգելակային ուժի բաշխում ebd cbc
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />

                  </>: null}


                {carSelectedData?.car_braking_assistant_eba_ba ?
                  <>
                    <EveryLineOfCarCharacteristics>մեքենայի արգելակման օգնական eba ba
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                : null}

                {carSelectedData?.car_body_stabilization_system_esp_dsc ?
                  <>
                    <EveryLineOfCarCharacteristics>մեքենայի մարմնի կայունացման համակարգ esp dsc
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}

                {carSelectedData?.car_active_braking ?
                  <>
                    <EveryLineOfCarCharacteristics>{t("car_active_braking")}
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}

                {carSelectedData?.car_lane_keeping_assistant ?
                  <>
                    <EveryLineOfCarCharacteristics>{t("car_lane_keeping_assistant")}
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}

                {carSelectedData?.car_consultants_design_development_engineering ?
                  <>
                    <EveryLineOfCarCharacteristics>{t("car_consultants_design_development_engineering")}
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}

                {carSelectedData?.car_child_seat_interface ?
                  <>
                    <EveryLineOfCarCharacteristics>{t("car_child_seat_interface")}
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}

                {carSelectedData?.car_hill_assistant ?
                  <>
                    <EveryLineOfCarCharacteristics>{t("car_hill_assistant")}
                      <span>ՍՏԱՆԴԱՐՏ</span>
                    </EveryLineOfCarCharacteristics>
                    <SimpleLine />
                  </>
                  : null}
              </ItemForCarInfo>
            </MainBlocksWrapper>

          </CarPageContent>
        )}
      </CarPageWrapper>
    </>
  );
};
