import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { websiteMainData } from "store/websiteMainData/actions";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { descriptionMappings } from "utils/descriptions";
import { keywordMappings } from "utils/keywords";
import { NewHeader } from "../../components/newHeader";
import { LayoutForNewDesign } from "../../components/layout-for-new-design";

export const MainWebsiteWrapper = ({ children }) => {
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'google-site-verification';
    meta.content = 'TjwEU4emC2kcxZKT-CYhLawTWmLxqhX3nxxIW7iLDwg';
    document.head.appendChild(meta);
  }, [location]);

  const metaTag = document.querySelector("meta[name=\"description\"]");

  useEffect(() => {
    const route = location.pathname;
    const descriptionMapping = descriptionMappings[route];
    const keywordsMapping = keywordMappings[route];

    if (keywordsMapping) {
      setMetaKeywords(keywordsMapping[language] || "");
    }

    if (descriptionMapping) {
      setMetaDescription(descriptionMapping[language] || "");
    }
  }, [location.pathname, language, metaTag]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (language === "en") {
        document.title = "Էլեկտրոմոբիլներ | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Էլեկտրոմոբիլներ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Էլեկտրոմոբիլներ | Ecomotors.am";
      } else {
        document.title = "Էլեկտրոմոբիլներ | Ecomotors.am";
      }
    } else if (location.pathname === "/blog") {
      if (language === "en") {
        document.title = "Blogs | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Բլոգ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Блог | Ecomotors.am";
      } else {
        document.title = "Blogs | Ecomotors.am";
      }
    } else if (location.pathname.includes("/blog")) {
      if (language === "en") {
        document.title = "Blogs | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Բլոգ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Блог | Ecomotors.am";
      } else {
        document.title = "Blogs | Ecomotors.am";
      }
    } else if (location.pathname === "/services") {
      if (language === "en") {
        document.title = "Services | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Ծառայություններ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Услуги | Ecomotors.am";
      } else {
        document.title = "Services | Ecomotors.am";
      }
    } else if (location.pathname === "/credit-calculator") {
      if (language === "en") {
        document.title = "Credit calculator | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Վարկի Հաշվիչ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Кредитный Калькулятор | Ecomotors.am";
      } else {
        document.title = "Credit calculator | Ecomotors.am";
      }
    } else if (location.pathname === "/cars") {
      if (language === "en") {
        document.title = "Cars | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Մեքենաներ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Машины | Ecomotors.am";
      } else {
        document.title = "Cars | Ecomotors.am";
      }
    } else if (location.pathname === "/eco-motors/sign-in") {
      if (language === "en") {
        document.title = "Sign In | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Մուտք | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Войти | Ecomotors.am";
      } else {
        document.title = "Sign In | Ecomotors.am";
      }
    } else if (location.pathname === "/eco-motors/sign-up") {
      if (language === "en") {
        document.title = "Sign Up | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Գրանցում | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Регистрация | Ecomotors.am";
      } else {
        document.title = "Sign Up | Ecomotors.am";
      }
    } else if (location.pathname === "/about") {
      if (language === "en") {
        document.title = "About Us | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Մեր Մասին | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "О Нас | Ecomotors.am";
      } else {
        document.title = "About Us | Ecomotors.am";
      }
    } else if (location.pathname === "/contact") {
      if (language === "en") {
        document.title = "Contact Us | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Կապ Մեզ Հետ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Связаться с Нами | Ecomotors.am";
      } else {
        document.title = "Contact Us | Ecomotors.am";
      }
    } else if (location.pathname === "/reviews" || location.pathname.includes("reviews")) {
      if (language === "en") {
        document.title = "Reviews | Ecomotors.am";
      } else if (language === "am") {
        document.title = "Կարծիքներ | Ecomotors.am";
      } else if (language === "ru") {
        document.title = "Отзывы | Ecomotors.am";
      } else {
        document.title = "Reviews | Ecomotors.am";
      }
    }  else {
      document.title = "Electromobile | Ecomotors.am";
    }
  }, [language, location]);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(websiteMainData(language));
  }, [dispatch, language]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    // eslint-disable-next-line no-undef
    fbq('init', '2229163863941053');
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description"
              content={metaDescription} />
        <meta name="keywords"
              content={metaKeywords} />
        <link rel="canonical" href={`https://www.ecomotors.am${location.pathname}`} />
        {/*Google tag (gtag.js)*/}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YD7M8Z2F7J"></script>
      </Helmet>
      <NewHeader />
      <LayoutForNewDesign>
        {children}
      </LayoutForNewDesign>
    </>
  );
};

