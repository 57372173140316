import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CarSpecialPageSlider } from "components/cars/carSpecialPageSlider.js";
import { useDispatch, useSelector } from "react-redux";
import { selectCarSlug, selectCarStatus } from "store/carSinglePage/selector";
import { Images } from "assets";
import { Loading } from "components/loading";
import { selectWebsiteMainData } from "store/websiteMainData/selector";
import { websiteMainData } from "store/websiteMainData/actions";
import { useTranslation } from "react-i18next";
import { useCloseOnClickOutside } from "hooks/useCloseOnClickOutside";
import { currencyExchange } from "store/currencyExchange/action";
import { IMAGE_STARTING_URL, REDUX_STATES } from "utils/constants";
import { CreditModal } from "modals/creditModal";
import { resetStatus } from "store/favourite/slice";
import {
  AboutAHref,
  AboutCarBlock,
  AboutCarKey,
  AboutCarValue,
  CallNumber,
  CallNumberHref,
  CallSellerAndInfoAboutSeller,
  CallToPartner,
  CallToPartnerSilver,
  CallToSeller,
  CarCharacteristics,
  CarName,
  CarPageCarPriceRight,
  CarPageContent,
  CarPageLeftSide,
  CarPageRightSide,
  CarPageWrapper,
  CarPriceAndNameWrapper,
  CarRightSideTitle,
  CarRightSideTitleH1,
  CarSpecialPageSliderLeft,
  CarSpecialPagesSliderRight,
  CarsWithOrderText,
  CarTitleAndAddFavorite, EveryLineOfCarCharacteristics,
  InfoAboutAHref,
  InfoAboutAHrefBlack, ItemForCarInfo, MainBlocksWrapper, MainInfoAboutCar,
  PhoneImage, SimpleLine
} from "containers/carPage/styled.js";
import { mopedSlug } from "../../store/mopedSingleInfo/actions";
import { selectMopedData } from "../../store/mopedSingleInfo/selector";
import { NumberWithDots } from "../../HOC/numberWithDots";
import { About } from "../about";
import { InvisibleH1 } from "../../components/welcome/styled";
import { NotFound } from "../notFound";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDone, MdElectricBolt } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

export const MopedsSinglePage = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation();
  const carSelectedData = useSelector(selectMopedData);
  const carSelectedStatus = useSelector(selectCarStatus);
  const selectWebsiteData = useSelector(selectWebsiteMainData);
  const { secondRef, secondIsOpen, setSecondIsOpen } = useCloseOnClickOutside();
  const { thirdRef, thirdIsOpen, setThirdIsOpen } = useCloseOnClickOutside();

  const handleCreditModal = () => {
    setSecondIsOpen(!secondIsOpen);
  };

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    dispatch(currencyExchange());
  }, [dispatch]);

  useEffect(() => {
    if (!secondIsOpen) {
      document.body.style.overflow = "unset";
    }
  });

  useEffect(() => {
    dispatch(websiteMainData(language));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(mopedSlug(slug));
  }, [dispatch, language, location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
      block: "start"
    });
  }, []);

  useEffect(() => {
    dispatch(resetStatus());
  }, [slug]);

  useEffect(() => {
    const title = carSelectedData?.product_name?.car_name + " " + carSelectedData?.product_model?.model_name;
    const imageUrl = `${IMAGE_STARTING_URL}/${carSelectedData?.product_images?.[0]?.image}`;

    const setMetaTags = () => {
      const metaTags = [
        { property: "og:title", content: title },
        { property: "og:image", content: imageUrl }
      ];

      metaTags.forEach((tag) => {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("property", tag.property);
        metaTag.setAttribute("content", tag.content);
        document.head.appendChild(metaTag);
      });
    };

    setMetaTags();

    return () => {
      const metaTags = document.querySelectorAll("meta[property^=\"og:\"]");
      metaTags.forEach((tag) => {
        document.head.removeChild(tag);
      });
    };
  }, [carSelectedData]);

  return (
    <>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
      <CarPageWrapper>
        {carSelectedData?.length === 0 ||
        carSelectedStatus === REDUX_STATES.PENDING ? (
          <Loading />
        ) : carSelectedData == null ? (
          <NotFound />
        ) : (
          <CarPageContent>
            <CarPriceAndNameWrapper>

              <CarName>{carSelectedData?.product_name?.motorcycle_name}{" "}
                {carSelectedData?.product_model?.motorcycle_model}</CarName>
              <CarPageCarPriceRight>{NumberWithDots(+carSelectedData.motorcycle_price)} $</CarPageCarPriceRight>
            </CarPriceAndNameWrapper>
            <CarPageLeftSide>
              <CarSpecialPageSliderLeft>
                <CarSpecialPageSlider
                  carData={carSelectedData}
                />
              </CarSpecialPageSliderLeft>
              <CarSpecialPagesSliderRight>
                <CallToPartner href={`tel:+374 95 94 94 66`}>
                  <FaPhoneAlt />
                  <InfoAboutAHref>
                    <AboutAHref>+374 95 94 94 66 </AboutAHref>
                    <AboutAHref>Մանրամասների համար</AboutAHref>
                  </InfoAboutAHref>
                </CallToPartner>

                <CallToPartnerSilver href={`tel:+374 95 94 94 66`}>
                  <MdElectricBolt />
                  <InfoAboutAHrefBlack>
                    <AboutAHref>Գրանցվել Տեստ Դրայվի</AboutAHref>
                  </InfoAboutAHrefBlack>
                </CallToPartnerSilver>

                <CallToPartnerSilver href={`tel:+374 95 94 94 66`}>
                  <IoMdSettings style={{ color: "orange" }}/>
                  <InfoAboutAHrefBlack>
                    <AboutAHref>Պահեստամասեր և դետալներ</AboutAHref>
                  </InfoAboutAHrefBlack>
                </CallToPartnerSilver>
              </CarSpecialPagesSliderRight>
            </CarPageLeftSide>

            <MainInfoAboutCar>Հիմնական Բնութագիր</MainInfoAboutCar>
            <MainBlocksWrapper>
              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>{t("motorcycle_power")}
                  <span>{carSelectedData?.motorcycle_power}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
                <EveryLineOfCarCharacteristics>{t("motorcycle_battery")}
                  <span>{carSelectedData?.motorcycle_battery}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_wheel_front")}
                  <span>{carSelectedData?.motorcycle_wheel_front} KW</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_wheel_rear")}
                  <span>{carSelectedData?.motorcycle_wheel_rear}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_max_weight")}
                  <span>{carSelectedData?.motorcycle_max_weight}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_weight")}
                  <span>{carSelectedData?.motorcycle_weight}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />


                <EveryLineOfCarCharacteristics>{t("motorcycle_max_speed")}
                  <span>{carSelectedData?.motorcycle_max_speed}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
              </ItemForCarInfo>

              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>{t("motorcycle_front_and_rear_disc_brakes")}
                  <span>{carSelectedData?.motorcycle_front_and_rear_disc_brakes}</span>
                </EveryLineOfCarCharacteristics>
                <EveryLineOfCarCharacteristics>{t("motorcycle_controller")}
                  <span>{carSelectedData?.motorcycle_controller}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_light")}
                  <span>{carSelectedData?.motorcycle_light}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_absorbing_front_rear")}
                  <span>{carSelectedData?.motorcycle_absorbing_front_rear}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_charging_time")}
                  <span>{carSelectedData?.motorcycle_charging_time}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_total_size")}
                  <span>{carSelectedData?.motorcycle_total_size}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />

                <EveryLineOfCarCharacteristics>{t("motorcycle_cruising_range")}
                  <span>{carSelectedData?.motorcycle_cruising_range}</span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
              </ItemForCarInfo>


              <ItemForCarInfo>
                <EveryLineOfCarCharacteristics>Կամֆորտ
                  <span><MdDone /></span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
                <EveryLineOfCarCharacteristics>Ինտերիեր
                  <span><MdDone /></span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
                <EveryLineOfCarCharacteristics>Անվտանգություն
                  <span><MdDone /></span>
                </EveryLineOfCarCharacteristics>
                <SimpleLine />
                <EveryLineOfCarCharacteristics>Մուլտիմեդիա
                  <span><MdDone /></span>
                </EveryLineOfCarCharacteristics>

              </ItemForCarInfo>
            </MainBlocksWrapper>


          </CarPageContent>
        )}
      </CarPageWrapper>
    </>
  );
};

