import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carsheader } from "components/cars/carsheader.js";
import { aboutUsData } from "store/aboutUs/actions";
import { ourTeamData } from "store/ourTeam/actions";
import { selectAboutUsData } from "store/aboutUs/selector";
import { Loading } from "components/loading";
import { selectOurTeamData } from "store/ourTeam/selector";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useTranslation } from "react-i18next";
import { IMAGE_STARTING_URL } from "utils/constants";
import {
  AboutUsBlock,
  AboutUsContainer,
  AboutUsWrapper,
  ChangeCarChangeTheWorld,
  ChangeCarChangeTheWorldText,
  ChangeCarTheWorldTextAbout,
  OurTeamCards,
  OurTeamText,
  OurTeamWrapper,
  OurWorkerCard,
  OurWorkerImage,
  TitleForEcoMotors,
  TitlesForLi,
  TitlesForUl,
  WhyToChooseEcoMotors,
  WorkerPosition
} from "containers/about/styled";
import { BigEcoMotorsLogo } from "../../assets/svgFiles/BigEcoMotorsLogo";


export const About = () => {
  const [language, setLanguage] = useState(localStorage.getItem("lang"));
  const dispatch = useDispatch();
  const AboutUsData = useSelector(selectAboutUsData);
  const selectOurTeam = useSelector(selectOurTeamData);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(aboutUsData({ language }));
    dispatch(ourTeamData(language));
  }, [dispatch, language]);

  useEffect(() => {
    function handleStorageChange() {
      setLanguage(localStorage.getItem("lang"));
    }

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <AboutUsWrapper>
      <Carsheader titleText={t("aboutUs")} />
      <ChangeCarChangeTheWorld>
        <ChangeCarChangeTheWorldText>Փոխի՛ր մեքենադ, փոխի՛ր աշխարհը</ChangeCarChangeTheWorldText>
        <BigEcoMotorsLogo />
        <ChangeCarTheWorldTextAbout>
          Eco Motors ընկերությունը զբաղվում է էլեկտրական մեքենաների ներմուծմամբ և վաճառքով:
          Ընկերությունն առաջարկում է առկա և պատվերով էլեկտրական մեքենաների լայն տեսականի:  Էլեկտրոմոբիլները կարող եք ձեռք բերել ինչպես կանխիկ, այնպես էլ ապառիկ ու լիզինգով:
        </ChangeCarTheWorldTextAbout>
      </ChangeCarChangeTheWorld>
      <WhyToChooseEcoMotors>
        <TitleForEcoMotors>Ecomotors ընկերություն՝</TitleForEcoMotors>
        <TitlesForUl>
          <TitlesForLi>Վստահելի գործընկեր</TitlesForLi>
          <TitlesForLi>Մասնաճյուղերի ձևավորվող ցանց Հայաստանում</TitlesForLi>
          <TitlesForLi>Լավագույն պայմաններ</TitlesForLi>
          <TitlesForLi>Մեքենաների ներմուծում ամենակարճ ժամանակահատվածում</TitlesForLi>
          <TitlesForLi>Հինգ տարի երաշխիքային սպասարկում կամ 150.000 կմ</TitlesForLi>
        </TitlesForUl>
      </WhyToChooseEcoMotors>

      {AboutUsData ? <AboutUsBlock>
          <AboutUsContainer>

            <OurTeamWrapper>
              <OurTeamText>{t("our_team")}</OurTeamText>
              <OurTeamCards>
                {selectOurTeam?.map((team) =>
                        (
                          <OurWorkerCard backgroundImage={`${IMAGE_STARTING_URL}/${team?.image}`}>
                            <OurWorkerImage src={`${IMAGE_STARTING_URL}/${team?.image}`}/>
                            <WorkerPosition>{team?.personal_full_name}</WorkerPosition>
                            <WorkerPosition role>{team?.personal_position}</WorkerPosition>
                          </OurWorkerCard>
                        )

                )}
              </OurTeamCards>
            </OurTeamWrapper>
          </AboutUsContainer>
        </AboutUsBlock> :
        <Loading />
      }
    </AboutUsWrapper>
  )
    ;
};
