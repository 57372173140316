import axios from "axios";
import { API } from "configs/api";

export const carSlugService = {
  car_slug: (data) => axios.get(`${API.car_slug}/${data.slug}?timestamp=${Date.now()}&lang=am`, {
    headers: {
      "Cache-Control": "no-store"
    }
  }),
  car_with_articulate: (data) => axios.get(`${API.car_articulate}?car_articulate=${data.search}`, {
    headers: {
      "Cache-Control": "no-store"
    }
  })
};
