export const carPriceFromOptions = [
  { value: "10000", label: "10000" },
  { value: "20000", label: "20000" },
  { value: "30000", label: "30000" },
  { value: "40000", label: "40000" },
  { value: "50000", label: "50000" },
  { value: "60000", label: "60000" },
  { value: "70000", label: "70000" },
  { value: "80000", label: "80000" },
  { value: "90000", label: "90000" },
  { value: "100000", label: "100000" },
  { value: "150000", label: "150000" },
  { value: "200000", label: "200000" },
  { value: "250000", label: "250000" },
  { value: "300000", label: "300000" },
  { value: "500000", label: "500000" }
];

export const carYearFromOptions = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

export const carYearToOptions = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];
