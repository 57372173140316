import styled from "styled-components";
import { Images } from "../../assets";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 172px;
  background: #032022;
  padding-inline: 20vw;
  position: relative;
    
  @media only screen and (max-width: 768px) {
      padding-inline: unset !important;
      height: 136px;
      gap: unset;
  }  
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 330px;
  background-color: #032022;
  padding: 20px;
  display: flex;
  gap: 10px;
  position: absolute;
  top: 97px;
    z-index: 20;
    
  @media only screen and (max-width: 768px) { 
    display: flex;
      flex-direction: column;
      height: auto;
      padding: unset;
      padding-inline: 20px;
      padding-bottom: 10px;
      z-index: 20;
  }
`;

export const MenuContainerSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const EcoMotorsAdvertisement = styled.img`
  width: 100%;
`;

export const EcoMotorsNumbersAndSocialLinks = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

export const NumbersAndEmail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const NumberText = styled.span`
  color: white;
  font-size: 20px;
  font-weight: bold;
    
  @media only screen and (max-width: 768px) {
      font-size: 12px;
  }  
`;

export const EmailText = styled.span`
  color: silver;
  font-size: 12px;
`;

export const SocialMediaLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .fa__icon {
    color: white;
  }
`;

export const Header = styled.h2`
  color: #4FEC00;
  font-size: 19px;
  font-weight: bold;
`;

export const HeaderSingleUnit = styled.span`
  color: #FFFFFF;
  transition: 0.4s linear;
  cursor: pointer;

  &:hover {
    color: #4FEC00;
  }
    
    @media only screen and (max-width: 768px) { 
        font-size: 12px;
    }

    @media only screen and (max-width: 424px) {
        font-size: 10px;
    }
`;

export const HeaderAdvertisement = styled.div`
  width: 100%;
  height: 66px;
  background: url(${Images.ruichiGif});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  flex-shrink: 0;

    @media only screen and (max-width: 768px) {
        background: url(${Images.ruichiGif2});
        height: 56px;
        background-size: cover;
    }
`;

export const MainHeaderItems = styled.div`
  width: 100%;
  position: relative;
`;

export const MainWrapperOfHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 42px;
  width: 100%;
  height: 100px;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding-inline: 20px;
        gap: 18px;
    }
`;

export const HamburgerIconWithLogo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 22px;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
        height: 70px;
    }
`;

export const HamburgerIcon = styled.img`
  cursor: pointer;

    @media only screen and (max-width: 768px) {
        width: 14px;
    }
`;

export const EcoMotorsLogo = styled.img`
  width: 176px;
  cursor: pointer;
    
    @media only screen and (max-width: 768px) { 
        width: 120px;
    }
`;

export const SearchWithLanguages = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 22px;
    
    @media only screen and (max-width: 768px) {
        display: none;
    } 
`;

export const HeaderSearchInput = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
    
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const HeaderSearchInputMini = styled.div`
  position: relative;
  width: 95%;
  height: 40px;
  margin: 0 auto;
    display: none;

    @media only screen and (max-width: 768px) {
        display: block;
    }
`;

export const SearchIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 38px;
  background: #032022;
  border-radius: 42px;
  position: absolute;
  top: 1px;
  right: 1px;
  transition: 0.4s linear;
  cursor: pointer;

  &:hover {
    background-color: rgba(3, 32, 34, 0.9);
  }

  .search__icon {
    color: #CCFF00;
    font-size: 18px;
  }

    @media only screen and (max-width: 768px) {
        width: 30px;
        height: 24px;
        top: 8px;

        .search__icon {
            font-size: 12px;
        }
    }
`;

export const HeaderSearch = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 58px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

export const LanguagesDropDown = styled.div`
  align-items: center;
  justify-content: space-between;
  width: 80px;
  height: 40px;
  color: white;
  display: none; 
    
    //TODO
    @media only screen and (max-width: 768px) {
        width: 60px;
        font-size: 10px;
        gap: 4px;
    }
`;

export const LanguageIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url(${Images.ArmLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

    @media only screen and (max-width: 768px) {
        width: 14px;
        height: 14px;
    }
`;

export const FilterParent = styled.div`
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: none;
    
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;

export const FilterWithButtonAndIcon = styled.div`
    width: 100px;
    height: 30px;
    padding: 10px;
    background: #F2F2F2;
    border-radius: 32px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
`;
