import styled from "styled-components";
import { DefaultFont } from "utils/defaultFont.js";
import { COLORS } from "utils/colors.js";

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`;

export const BlackWithOpacity = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
  z-index: 1;
`;

export const ContactusMainForm = styled.div`
  width: 100%;
  height: auto;
  border-radius: 25px;
  padding: 30px;
  z-index: 2;
  background: #E9F1EF;
`;

export const ContactUsForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 428px) {
    gap: 40px;
  }
`;

export const ContactUsTitle = styled.span`
  ${DefaultFont};
  font-size: 40px;
  color: #008900;
  text-transform: capitalize;
    text-align: center;
    font-weight: bold;

  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 450px;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 480px;
  height: 80px;

  @media only screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const StyledInputTitle = styled.span`
  ${DefaultFont};
  font-size: 18px;
  font-weight: 400;
  color: #008900;

  @media only screen and (max-width: 428px) {
    font-size: 14px;
  }
`;

export const StyledInput = styled.input`
  outline: unset;
  height: 40px;
  background: transparent;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 18px;
  border: 1px solid #008900;
  color: #008900;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  @media only screen and (max-width: 428px) {
    font-size: 14px;
  }
`;


export const StyledTextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledErrorMessage = styled.span`
  position: absolute;
  right: 0;
  top: 6px;
  color: red;
`;

export const StyledTextArea = styled.textarea`
  height: 118px;
  padding-left: 8px;
  resize: none;
  outline: unset;
  background: transparent;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #008900;
  color: #008900;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.main_color};
    border-radius: 4px;
  }
`;

export const SendMessageButton = styled.button`
  width: 220px;
  height: 40px;
  outline: none;
  border: 1px solid #008900;
  color: #008900;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  align-self: end;

  &:hover {
    border: 1px solid ${COLORS.white};
    color: ${COLORS.main_color};
  }

  @media only screen and (max-width: 428px) {
    algin-self: unset;
    width: 100%;
  }
`;
