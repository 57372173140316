import { OurPartnerImage, OurPartnersWrapper } from "./styled";
import { TopAnnouncmentText } from "../welcome/styled";
import { MiniEcoMotors } from "../../assets/svgFiles/miniEcoMotors";
import React from "react";
import { Images } from "../../assets";

export const OurPartners = () => {

  return (
    <OurPartnersWrapper>
      <TopAnnouncmentText><MiniEcoMotors /> ՄԵՐ ԳՈՐԾԸՆԿԵՐՆԵՐԸ</TopAnnouncmentText>
      <OurPartnerImage src={Images.ourPartnersBig} alt='our partner image' />
    </OurPartnersWrapper>
  )
}