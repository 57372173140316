import {
  EveryMotorCycleBlock, ImageOfMoto,
  MainMopedsWrapper,
  MainMotorCyclesWrapper,
  MopedsTitle, MotoName, MotoPrice,
  OpacityBlock,
  RedColorText
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { MotorcyclesAction } from "../../store/motorcycles/actions";
import { selectMotorCycleData } from "../../store/motorcycles/selector";
import { IMAGE_STARTING_URL } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTENAMES } from "../../routes/routeNames";
import { AvailableCarsForSingle } from "../../components/availableCarsForSingle";
import { CarsContentWrapper } from "../../components/cars/styled";
import { ContentTitle, ContentTitleWithAllText, ContentTitleWithTypes } from "../availableCars/styled";

export const Motorcycles = () => {
  const dispatch = useDispatch();
  const selectMotoInfo = useSelector(selectMotorCycleData);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(MotorcyclesAction())
  }, [dispatch]);

  return (
    <>
      <CarsContentWrapper>
        <ContentTitleWithTypes>
          <ContentTitleWithAllText>
            <ContentTitle>{t("motorcycles")}</ContentTitle>
            Բոլոր Մոտոցիկլետները
          </ContentTitleWithAllText>
        </ContentTitleWithTypes>
        <AvailableCarsForSingle filteredCars={selectMotoInfo} />
      </CarsContentWrapper>
    </>
  );
};