
import "react-lazy-load-image-component/src/effects/opacity.css";
import { CarListWrapper } from "components/cars/styled.js";
import { InvisibleH1 } from "../welcome/styled";
import React from "react";

export const Carsheader = ({ titleText }) => {
  return (
    <CarListWrapper>
      <InvisibleH1>Ecomotors ընկերությունը զբաղվում է էլեկտրական մեքենաների և լիցքակայանների ներմուծմամբ ու վաճառքով:
        Առաջարկում ենք առկա և պատվերով լավագույն տեսականին ամենահարմար գներով l Ecomotors.am</InvisibleH1>
      Գլխավոր > {titleText}
    </CarListWrapper>
  );
};
