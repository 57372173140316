import styled from "styled-components";

export const WelcomePageOfficialPartner = styled.img`
    width: 100%;
    height: 400px;
    margin-top: 10px;

    @media only screen and (max-width: 768px) {
        height: auto;
    }
`;
