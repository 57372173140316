import styled, { keyframes } from "styled-components";
import { COLORS } from "utils/colors";
import { Link } from "react-router-dom";

export const BlogContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const slideFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;
export const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;

  a {
    color: black;
  }
`;

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  min-height: 400px;
  height: auto;
  margin: 20px;
  border: 1px solid ${COLORS.white};
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: linear;
  transition-duration: 0.4s;
  position: relative;

  &:hover {
    box-shadow: 4px 4px 4px rgba(145, 198, 93, 0.9);
  }

  .lazy-load-image-background {
    width: 100%;
    height: 200px;
  }

  .lazy-load-image-background img {
    width: 100%;
    height: 200px !important;
    object-fit: contain;
  }
  
  @media only screen and (max-width: 428px) {
    width: ${({welcome}) => welcome ? "280px" : "300px"};
    margin: ${({welcome}) => welcome ? "0" : "20px"};
  }

  img {
    max-width: 100%;
    height: 200px;
    object-fit: contain;
  }
`;

export const BlogDetails = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  color: ${COLORS.black};
`;

export const BlogTitle = styled.h1`
  display: inline-block;
  font-size: 18px;
  width: 100%;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS.black};
`;

export const BlogAuthor = styled.p`
  font-size: 14px;
  margin: 10px;
  color: ${COLORS.black};
`;

export const BlogDate = styled.p`
  font-size: 14px;
  margin: 10px;
  color: ${COLORS.black};
`;

export const BlogDescription = styled.p`
  font-size: 16px;
  margin: 10px;
  text-align: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const LinkForBlock = styled(Link)`
  border-radius: 50%;
  background-color: royalblue;
`;

export const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;


export const BlogAnimatedTitle = styled.h1`
  font-size: 5vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-align: center;
  color: ${COLORS.main_color};
`;
