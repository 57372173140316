import styled from "styled-components";
import { COLORS } from "utils/colors.js";
import { DefaultFont } from "utils/defaultFont.js";

export const CarPageWrapper = styled.div`
  width: calc(100vw - 450px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

    @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-top: unset;
        padding-inline: 10px;
    }
`;

export const CarPageContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; 
    position: relative;
`;

export const CarPriceAndNameWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        margin-bottom: 10px;
    }
`;

export const CarPageCarPriceRight = styled.span`
    color: #008900;
    font-size: 30px;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

export const CarPageLeftSide = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    .swiper-button-prev {
        display: none;
    }

    .swiper-button-next {
        display: none;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

export const CarSpecialPageSliderLeft = styled.div`
    height: auto;
`;

export const CarSpecialPagesSliderRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
`;

export const CallToPartner = styled.a`
    background: #08CC46;
    width: 90%;
    height: 62px;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    gap: 20px;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const CallToPartnerSilver = styled.a`
    background: #E9F1EF;
    width: 90%;
    height: 62px;
    font-size: 20px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    gap: 20px;
    color: #000000 !important;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const InfoAboutAHrefBlack = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    color: #000000 !important;
    cursor: pointer;
`;

export const InfoAboutAHref = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    color: #FFFFFF;
    cursor: pointer;
`;

export const AboutAHref = styled.span`
    
`;

export const CarShareBlock = styled.div`
  width: 100%;

  .css-1xc3v61-indicatorContainer svg {
    color: ${COLORS.main_color};
  }
`;

export const CarShareDiv = styled.div`
  display: flex;
  justify-content: start;
  font-weight: 700;
  gap: 6px;
  align-items: center;

  span {
    color: ${COLORS.main_color};
    font-size: 18px;
  }

  img {
    width: 20px;
  }
`;

export const CarPageRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 504px;
  height: auto;
  min-height: 800px;
  flex-shrink: 0;
  border-left: 1px solid ${COLORS.main_color};

  @media only screen and (max-width: 1280px) {
    width: 100%;
    min-height: unset;
  }
`;

export const CarCharacteristics = styled.div`
  display: flex;
  flex-direction: column;
  width: 504px;
  height: auto;

  @media only screen and (max-width: 1280px) {
    width: 100%;
    min-height: unset;
  }
`;

export const CarTitleAndAddFavorite = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  border-bottom: 1px solid ${COLORS.main_color};
  padding-inline: 10px;
`;

export const CarRightSideTitle = styled.span`
  font-size: 1.1vw;
  color: ${COLORS.main_color};

  @media only screen and (max-width: 1280px) {
    font-size: 1.6vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14.5px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 11px;
  }
`;

export const CarRightSideTitleH1 = styled.span`
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 1.1vw;
  color: ${COLORS.main_color};
  text-transform: capitalize;

  @media only screen and (max-width: 1280px) {
    font-size: 1.6vw;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14.5px;
  }

  @media only screen and (max-width: 428px) {
    font-size: 11px;
  }
`;

export const AboutCarBlock = styled.div`
  width: 100%;
  height: auto;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({isArticulate}) => isArticulate ? "none" : `1px solid ${COLORS.main_color}`};
`;

export const AboutCarBlockKeyDiv = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 10px;
`;

export const AboutCarKey = styled.span`
  color: ${({ is_car_additional }) =>
          is_car_additional ? COLORS.main_color : COLORS.black};
  cursor: ${({ is_car_additional }) =>
          is_car_additional ? "pointer" : "unset"};
  font-size: ${({ is_car_additional }) =>
          is_car_additional ? "20px" : "14px"};
  font-weight: 600;
  ${DefaultFont};
  padding-inline: 10px;
  text-transform: capitalize;

  @media only screen and (max-width: 428px) {
    font-size: ${({ is_car_additional }) =>
            is_car_additional ? "14px" : "10px"};
  }
`;

export const AboutCarValue = styled.span`
  color: ${({ is_car_additional }) =>
          is_car_additional ? COLORS.main_color : COLORS.black};
  font-size: ${({ is_car_additional }) =>
          is_car_additional ? "20px" : "14px"};
  font-weight: 800;
  box-sizing: content-box;
  padding-right: 10px;
  ${DefaultFont};
  text-transform: capitalize;
  word-break: break-all;

  @media only screen and (max-width: 428px) {
    font-size: 12px;
  }
`;

export const CarName = styled.h1`
    color: #032022;
    font-size: 30px;
    font-weight: bold;

    @media only screen and (max-width: 428px) {
        font-size: 20px;
    }
`;

export const CallSellerAndInfoAboutSeller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${COLORS.main_color};
  padding-top: 10px;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  @media only screen and (max-width: 428px) {
    flex-direction: column;
  }
`;

export const CallToSeller = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 10px;
  padding-bottom: 8px;
`;

export const PhoneImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const CallNumberHref = styled.a`
  text-decoration: none;
`;

export const CallNumber = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  ${DefaultFont};
  color: ${({ isInModal }) => (isInModal ? COLORS.white : COLORS.main_color)};

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CarsWithOrderText = styled.span`
  font-size: 26px;
  color: ${COLORS.main_color};
  font-weight: 600;
  ${DefaultFont};
  position: relative;
  z-index: 10;
  cursor: pointer;
  text-align: ${({ isWithCredit }) => isWithCredit && "right"};

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: center;
  }

  &:hover {
    opacity: 0.9;
    color: #59931f;
  }
`;

export const MainInfoAboutCar = styled.span`
    width: 100%;
    font-size: 15px;
    color: #032022;
    margin-top: 20px;
    line-height: 24px;
    font-weight: bold;
    padding-inline: 10px;
`;

export const MainBlocksWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding-inline: 10px;
    padding-bottom: 10px;

    & > :nth-child(3) {
        grid-column: span 2;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 10px; 
    }
`;


export const ItemForCarInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 400px;
    border-radius: 20px;
    padding-inline: 10px;
    background: #E9F1EF;
`;

export const EveryLineOfCarCharacteristics = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    text-transform: capitalize;
`;

export const SimpleLine = styled.div`
    border: 1px solid rgba(0, 137, 0, 0.19);
`;
